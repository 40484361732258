import React, { useState } from 'react';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import classNames from 'classnames';
import StudentDetails from './StudentDetails';
import { CSVLink } from 'react-csv';
import { buttonStyle } from 'global/helper';

const ShowApplications = ({ data }: { data: any }) => {
  const [userId, setUserId] = useState(null);

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Stream', key: 'stream' },
    { label: 'Graduation Year', key: 'graduationYear' },
    { label: 'Course', key: 'course' },
    { label: 'Stage', key: 'stage' },
    { label: 'Applied On', key: 'appliedOn' },
  ];

  const exportData = data.map((el: any) => {
    const lastName = el.userId?.lastName || '';
    return {
      name: el.userId.firstName + ' ' + lastName,
      email: el.userId.email,
      stream: el.userId.stream,
      course: el.userId.course,
      graduationYear: el.userId.graduationYear,
      stage: el.currentStage,
      appliedOn: format(new Date(el.appliedOn), 'do LLLL yyyy'),
    };
  });

  const roleName = data[0]?.placementRoleId?.title;
  return (
    <div className='overflow-x-auto'>
      <div className='m-6 text-center font-semibold '>
        {' '}
        User Details for Role - {roleName}{' '}
      </div>

      {userId ? (
        <StudentDetails userId={userId} setUserId={setUserId} />
      ) : (
        <>
          <div className='flex justify-end mr-10'>
            <CSVLink
              data={exportData}
              headers={headers}
              className={buttonStyle}
            >
              Export Data
              <ArrowUpOnSquareIcon
                className='ml-3 -mr-1 h-5 w-5'
                aria-hidden='true'
              />
            </CSVLink>
          </div>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                    >
                      Name
                    </th>

                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Course
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Status
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Stage
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Graduation Year
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Applied On
                    </th>
                    <th
                      scope='col'
                      className='relative py-3.5 pl-3 pr-4 sm:pr-6'
                    >
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {data.map((application: any) => (
                    <tr key={application._id}>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        <div className='flex items-center'>
                          <div className='ml-4'>
                            <div className='font-medium text-gray-900'>
                              {application.userId.firstName}{' '}
                              {application.userId.lastName}
                            </div>
                            <div className='text-gray-500'>
                              {application.userId.email}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        <div className='text-gray-900'>
                          {application.userId.course}
                        </div>
                        <div className='text-gray-500'>
                          {application.userId.stream}
                        </div>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm'>
                        <span
                          className={classNames(
                            'inline-flex rounded-full  px-2 py-1 text-xs font-semibold leading-5 ',
                            {
                              'bg-green-100 text-green-800':
                                application.currentStatus === 'Hired',
                            },
                            {
                              'bg-blue-100 text-blue-800':
                                application.currentStatus === 'Applied',
                            },
                            {
                              'bg-yellow-100 text-yellow-800':
                                application.currentStatus === 'Shortlisted',
                            },
                            {
                              'bg-red-100 text-red-800':
                                application.currentStatus === 'Not Hired',
                            }
                          )}
                        >
                          {application.currentStatus}
                        </span>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {application.currentStage}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {application.userId.graduationYear}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {format(
                          new Date(application.appliedOn),
                          'do LLLL yyyy'
                        )}
                      </td>
                      <td className='whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                        <button
                          onClick={() => setUserId(application.userId._id)}
                          className='text-indigo-600 hover:text-indigo-900'
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>{' '}
        </>
      )}
    </div>
  );
};

export default ShowApplications;
