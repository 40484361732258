import FullProfile from 'components/personalInformation';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

import StudentApplications from './StudentApplications';
import Tabs from './Tabs';
import { useGetStudentQuery } from './PartnerStudents';

const ViewStudent = () => {
  const [view, setView] = useState('Profile');
  const { slug } = useParams();

  const { isSuccess, data } = useGetStudentQuery({ userId: slug });

  const pages = useMemo(() => {
    const initial = [{ name: 'Students', href: '/students', current: false }];
    if (isSuccess) {
      initial.push({
        name: data?.firstName + ' ' + data?.lastName,
        href: '/students/view/' + slug,
        current: true,
      });
    }
    return initial;
  }, [data?.firstName, data?.lastName, slug, isSuccess]);

  return (
    <>
      <nav className='flex mb-4' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to='/' className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
                <Link
                  to={page.href}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <Tabs view={view} setView={setView} />
      {view === 'Profile' ? (
        <FullProfile userId={slug} />
      ) : (
        <StudentApplications userId={slug} />
      )}
    </>
  );
};

export default ViewStudent;
