import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import Header from './Header';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import Loading from 'layout/Loading';
import Error from 'layout/Error';
import { format } from 'date-fns';
import {
  useDeleteAnnouncementMutation,
  useGetAnnouncementsQuery,
} from 'features/announcements/announcementSlice';
import NoAnnouncements from './NoAnnouncements';
import { toast } from 'react-toastify';

const AllAnnouncements = () => {
  const pages = [
    { name: 'Announcements', href: '/announcements', current: true },
  ];
  const { isError, isLoading, isSuccess, data } = useGetAnnouncementsQuery({
    limit: 0,
  });
  const [announcement, setAnnouncement] = useState<any>();
  const [loading, setLoading] = useState(false);
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(announcement: any) {
    setIsOpen(true);
    setAnnouncement(announcement);
  }
  const [deleteAnnouncement] = useDeleteAnnouncementMutation();

  return (
    <div>
      <nav className='flex mb-4' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to='/' className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
                <Link
                  to={page.href}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <Header />
      <>
        {isLoading && <Loading />}
        {isError && <Error />}
        {isSuccess &&
          (data.length ? (
            <div className='mt-8 flex flex-col'>
              <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                  <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                    <table className='min-w-full divide-y divide-gray-300'>
                      <thead className='bg-gray-50'>
                        <tr>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                          >
                            Title
                          </th>
                          <th
                            scope='col'
                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Courses
                          </th>
                          <th
                            scope='col'
                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Streams
                          </th>
                          <th
                            scope='col'
                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Years
                          </th>
                          <th
                            scope='col'
                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Added on
                          </th>
                          <th
                            scope='col'
                            className='relative py-3.5 pl-3 pr-4 sm:pr-6'
                          >
                            <span className='sr-only'>Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200 bg-white'>
                        {data?.map((announcement: any) => (
                          <tr key={announcement._id}>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                              {announcement.title}
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              {announcement.courses.length
                                ? announcement.courses.join(', ')
                                : 'ALL'}
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              {announcement.streams.length
                                ? announcement.streams.join(', ')
                                : 'ALL'}
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              {announcement.graduationYears.length
                                ? announcement.graduationYears.join(', ')
                                : 'ALL'}
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              {format(
                                new Date(announcement.createdAt),
                                'qo MMM u'
                              )}
                            </td>
                            <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-gray-500 font-medium sm:pr-6'>
                              <TrashIcon
                                onClick={() => {
                                  openModal(announcement);
                                }}
                                className='text-red-300 hover:text-red-400 cursor-pointer w-6 inline mr-4'
                              />
                              <Link
                                to={`/announcements/view/${announcement._id}`}
                                className=' hover:text-gray-900 mr-4'
                              >
                                <EyeIcon className='w-6 inline' />
                              </Link>
                              <Link
                                to={`/announcements/edit/${announcement._id}`}
                                className='hover:text-gray-900'
                              >
                                <PencilSquareIcon className='w-6 inline' />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <NoAnnouncements />
          ))}
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as='div' className='relative z-10' onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-black bg-opacity-25' />
            </Transition.Child>

            <div className='fixed inset-0 overflow-y-auto'>
              <div className='flex min-h-full items-center justify-center p-4 text-center'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 scale-95'
                  enterTo='opacity-100 scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 scale-100'
                  leaveTo='opacity-0 scale-95'
                >
                  <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-medium leading-6 text-gray-900'
                    >
                      Delete Announcement
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='font-semibold'>
                        Are you sure you want to remove the following
                        announcement?
                      </p>
                      <p>{announcement?.title}</p>
                      <div className='mt-8 flex justify-between'>
                        <div>
                          <button
                            className='px-8 py-2 bg-primary-light rounded-lg'
                            onClick={() => closeModal()}
                          >
                            Cancel
                          </button>
                        </div>
                        <div>
                          <button
                            disabled={loading}
                            onClick={() => {
                              setLoading(true);
                              deleteAnnouncement({
                                announcementId: announcement._id,
                              })
                                .unwrap()
                                .then((res: any) => closeModal())
                                .catch((err: any) => {
                                  toast.error('Something went wrong');
                                });
                            }}
                            className='px-8 py-2 bg-red-400 rounded-lg'
                          >
                            {loading ? 'Deleting' : 'Delete'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    </div>
  );
};

export default AllAnnouncements;
