import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncement: builder.query<any, any>({
      query: (params) => {
        return {
          url: '/placement-announcement',
          params: params,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Announcement'],
    }),
    getAnnouncements: builder.query<any, any>({
      query: (params) => {
        return {
          url: '/placement-announcement',
          params: params,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Announcement'],
    }),
    addAnnouncement: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/placement-announcement`,
          method: 'POST',
          body,
        };
      },
      // Invalidates all queries that subscribe to the `Partner`.
      invalidatesTags: ['Announcement'],
    }),
    updateAnnouncement: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/placement-announcement`,
          method: 'PUT',
          body,
        };
      },
      // Invalidates all queries that subscribe to the `Partner`.
      invalidatesTags: ['Announcement'],
    }),
    deleteAnnouncement: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/placement-announcement`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['Announcement'],
    }),
  }),
});

export const {
  useGetAnnouncementQuery,
  useGetAnnouncementsQuery,
  useUpdateAnnouncementMutation,
  useAddAnnouncementMutation,
  useDeleteAnnouncementMutation,
} = extendedApiSlice;
