import { useGetRolesQuery } from 'features/roles/RoleSlice';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import RolesDropdown from './RolesDropdown';
import { addApplications } from 'features/students/helper';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { apiSlice } from 'api/apiSlice';

const RolesPopup = ({
  selectedPeople,
  numApplications,
  isOpen,
  setIsOpen,
}: {
  selectedPeople: string[];
  numApplications: number;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) => {
  const [selectedRole, setselectedRole] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [parameters, setParameters] = useState<any>({ limit: 0 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isSuccess } = useGetRolesQuery({
    limit: 0,
  });

  function initiate() {
    setLoading(true);
    setError(false);
    setSuccess(false);
    setParameters({ limit: 0 });
    if (selectedRole) {
      addApplications(selectedRole, selectedPeople)
        .then((res: any) => {
          dispatch(apiSlice.util.invalidateTags(['Applications']));
          setSuccess(true);
          setParameters({ ...parameters, placementRoleId: selectedRole.id });
          setTimeout(() => {
            navigate(
              '/cycle/view/' +
                selectedRole.placementCycleId +
                '/roles/' +
                selectedRole.id,
              { replace: true }
            );
          }, 2000);
        })
        .catch((res: any) => {
          setError(true);
          setLoading(false);
        });
    } else {
      setIsOpen(false);
      setLoading(false);
    }
  }

  const errorContent = (
    <div className='rounded-md bg-red-50 p-4'>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
        </div>
        <div className='ml-3'>
          <h3 className='text-sm font-medium text-red-800'>
            There were errors with your request
          </h3>
          <div className='mt-2 text-sm text-red-700'>
            <ul className='list-disc space-y-1 pl-5'>
              <li>Please try again</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  const successContent = (
    <div className='rounded-md bg-green-50 p-4'>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <CheckCircleIcon
            className='h-5 w-5 text-green-400'
            aria-hidden='true'
          />
        </div>
        <div className='ml-3'>
          <h3 className='text-sm font-medium text-green-800'>
            Applications Added
          </h3>
          <div className='mt-2 text-sm text-green-700'>
            <p>Redirecting you to the roles page</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isSuccess && (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-10'
            onClose={() => {
              setIsOpen(false);
              setError(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-black bg-opacity-25' />
            </Transition.Child>

            <div className='fixed inset-0 overflow-y-auto'>
              <div className='flex min-h-full items-center justify-center p-4 text-center'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 scale-95'
                  enterTo='opacity-100 scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 scale-100'
                  leaveTo='opacity-0 scale-95'
                >
                  <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-medium leading-6 text-gray-900'
                    >
                      {`Add ${numApplications} ${
                        numApplications > 1 ? 'applications' : 'application'
                      } to the role`}
                    </Dialog.Title>
                    <div className='mt-8 h-32'>
                      <RolesDropdown
                        data={data}
                        setselectedRole={setselectedRole}
                      />
                    </div>

                    {error && errorContent}
                    {success && successContent}

                    <div className='mt-8'>
                      <button
                        disabled={loading}
                        type='button'
                        className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                        onClick={initiate}
                      >
                        {loading ? 'Processing' : 'Add Applications'}
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

export default RolesPopup;
