import React, { useEffect } from 'react';
import {
  ErrorMessage,
  Field,
  Formik,
  Form,
  FormikErrors,
  useFormikContext,
  FieldArray,
} from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorMessageStyle } from 'global/helper';
import { useGetPartnerQuery } from 'features/PartnerSlice';

const StreamDetails = ({
  setView,
  handleSwitch,
}: {
  setView: (value: number) => void;
  handleSwitch: any;
}) => {
  const { data, isSuccess } = useGetPartnerQuery();
  const streams = data?.partnerId?.streams || [];
  const initialValues = {
    streams: streams.length ? streams : [''],
  };
  type FormValues = {
    streams: string[];
  };

  const ShowErrorsInToast = () => {
    // Grab values and submitForm from context
    const {
      errors,
      isSubmitting,
    }: {
      errors: FormikErrors<FormValues>;
      isSubmitting: boolean;
    } = useFormikContext();

    useEffect(() => {
      if (errors && isSubmitting) {
        //First dismiss all toasts
        toast.dismiss();
        //Show errors in toast
        Object.values(errors).forEach((value: any) => {
          toast.error(value);
        });
      }
    }, [errors, isSubmitting]);

    return null;
  };
  const inputStyle =
    'mt-1 block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm';
  return (
    <>
      <ToastContainer />
      {isSuccess && data && (
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            streams: Yup.array().of(
              Yup.string().required('Stream is required')
            ),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSwitch({ type: 'UPDATE', values });
            setView(4);
            setSubmitting(false);
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <>
              <ShowErrorsInToast />
              <div className='px-4 py-5 sm:p-6'>
                <Form>
                  <div className='grid grid-cols-6 gap-4'>
                    <div className='col-span-6 sm:col-span-3'>
                      <label
                        htmlFor='firstName'
                        className='block text-sm font-medium text-neutral-900 md:font-bold'
                      >
                        Streams offered to students
                      </label>
                      <FieldArray
                        name='streams'
                        render={(arrayHelpers) => (
                          <div>
                            {values.streams && values.streams.length > 0 ? (
                              values.streams.map((stream: any, index: any) => (
                                <div key={index} className='flex gap-4'>
                                  <Field
                                    name={`streams.${index}`}
                                    className={inputStyle}
                                  />
                                  <div className='flex gap-4'>
                                    <button
                                      type='button'
                                      onClick={() => arrayHelpers.remove(index)} // remove a stram from the list
                                    >
                                      -
                                    </button>
                                    <button
                                      type='button'
                                      onClick={() => arrayHelpers.push('')} // insert an empty string at a position
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <button
                                type='button'
                                onClick={() => arrayHelpers.push('')}
                                className='mt-1'
                              >
                                {/* show this when user has removed all stream from the list */}
                                Add a stream
                              </button>
                            )}
                          </div>
                        )}
                      />
                      <div className={errorMessageStyle}>
                        <ErrorMessage name='streams' />
                      </div>
                    </div>
                  </div>
                  <div className='grid grid-cols-12 gap-4 mt-4'>
                    <div className='col-span-12 text-right'>
                      <button
                        disabled={isSubmitting}
                        className='bg-primary px-8 py-2 text-white font-light  text-sm rounded'
                      >
                        {isSubmitting ? 'Updating' : 'Next'}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default StreamDetails;
