import React, { useCallback } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

const SearchBox = ({
  setSearchKeyword,
  searchkey,
  setSearchKey,
}: {
  setSearchKeyword: any;
  searchkey: any;
  setSearchKey: any;
}) => {
  const debounce = (fn: any, delay: any) => {
    let timerId: any;
    return (...args: any) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => fn(...args), delay);
    };
  };

  const debouncedHandler = useCallback(
    (value: any, duration = 1200) => {
      debounce(() => setSearchKeyword(value), duration)();
    },
    [setSearchKeyword]
  );
  return (
    <div className='mb-2'>
      <label htmlFor='search-field' className='sr-only'>
        Search
      </label>
      <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center'>
          <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
        </div>
        <input
          value={searchkey}
          id='search-field'
          className='block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm'
          placeholder='Search'
          type='search'
          name='search'
          onChange={(e) => {
            setSearchKey(e.target.value);
            debouncedHandler(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default SearchBox;
