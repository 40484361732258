import { BuildingOfficeIcon, UsersIcon } from '@heroicons/react/20/solid';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function Tabs({
  stages,
  view,
  setView,
}: {
  stages: string[];
  view: string;
  setView: (view: string) => void;
}) {
  const tabs = [
    {
      name: 'All Applications',
      icon: UsersIcon,
      current: view === 'All Applications',
    },
  ];

  stages.forEach((elm: any) => {
    tabs.push({
      name: elm,
      icon: BuildingOfficeIcon,
      current: view === elm,
    });
  });

  const numTitle = [
    '',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
    'X',
  ];

  return (
    <div>
      <div className='sm:hidden'>
        <label htmlFor='tabs' className='sr-only'>
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id='tabs'
          name='tabs'
          className='block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
          defaultValue={tabs.find((tab) => tab.current)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className='hidden sm:block'>
        <div className='border-b border-gray-200'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            {tabs.map((tab, index) => (
              <p
                onClick={() => {
                  setView(tab.name);
                }}
                key={tab.name}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                <span
                  className={classNames(
                    tab.current
                      ? 'text-indigo-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    ' h-5 w-5 text-lg leading-5 font-semibold'
                  )}
                  aria-hidden='true'
                >
                  {numTitle[index]}
                </span>
                <span>{tab.name}</span>
              </p>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
