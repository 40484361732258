import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ChartData = ({ data }: any) => {
  const options = {
    labels: ['Applied', 'Hired', 'Shortlisted', 'Not Hired'],
  };
  const series = [
    data.appliedApplications,
    data.hiredApplications,
    data.shortlistedApplications,
    data.notHiredApplications,
  ];
  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type='donut'
        width='380'
      />
    </div>
  );
};

export default ChartData;
