import { Form } from 'formik';
import { saveButtonStyle } from 'global/helper';
import { useGetindustryQuery } from 'global/slice';
import { Select, TextArea, TextInput } from 'layout/FormElements';
import { useNavigate } from 'react-router-dom';
import EmployerLogo from './EmployerLogo';

export default function EmployerForm({
  isSubmitting,
  setFieldValue,
  values,
}: {
  isSubmitting: boolean;
  setFieldValue: any;
  values: any;
}) {
  const { data = [] } = useGetindustryQuery();
  const navigate = useNavigate();
  return (
    <div>
      <Form className='space-y-8 divide-y divide-gray-200 '>
        <div className='space-y-8 divide-y divide-gray-200'>
          <div>
            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
              <div className='sm:col-span-3'>
                <TextInput
                  name='name'
                  type='text'
                  placeholder='Acme Corp.'
                  label='Name'
                />
              </div>
              <div className='sm:col-span-3'>
                <TextInput
                  name='website'
                  type='text'
                  placeholder='https://acme.org'
                  label='Website'
                />
              </div>
              <div className='sm:col-span-3'>
                <Select name='industryId' label='Industry'>
                  <option value=''>Select Industry</option>
                  {data?.map((industry: any) => {
                    return (
                      <option value={industry._id} key={industry._id}>
                        {industry.title}
                      </option>
                    );
                  })}
                  <option value='Software Develeopment'>
                    Software Develeopment
                  </option>
                </Select>
              </div>
              <div className='sm:col-span-3'>
                <EmployerLogo values={values} setFieldValue={setFieldValue} />
              </div>
              <div className='sm:col-span-6'>
                <TextArea label='About' name='companyDescription' />
              </div>

              <div className='sm:col-span-6 hidden'>
                <label
                  htmlFor='photo'
                  className='block text-sm font-medium text-gray-700'
                >
                  Logo
                </label>
                <div className='mt-1 flex items-center'>
                  <span className='h-12 w-12 overflow-hidden rounded-full bg-gray-100'>
                    <svg
                      className='h-full w-full text-gray-300'
                      fill='currentColor'
                      viewBox='0 0 24 24'
                    >
                      <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                    </svg>
                  </span>
                  <button
                    type='button'
                    className='ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  >
                    Change
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='pt-8'>
            <div>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                Contact Details
              </h3>
              <p className='mt-1 text-sm text-gray-500'>
                Add company contact details
              </p>
            </div>
            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
              <div className='sm:col-span-3'>
                <TextInput
                  name='contactPerson[0].name'
                  type='text'
                  placeholder='Name'
                  label='Contact Person Name'
                />
              </div>
              <div className='sm:col-span-3'>
                <TextInput
                  name='contactPerson[0].email'
                  type='email'
                  placeholder='Email'
                  label='Contact Person Email'
                />
              </div>
              <div className='sm:col-span-3'>
                <TextInput
                  name='contactPerson[0].contactNumber'
                  type='text'
                  placeholder='Number'
                  label='Contact Person Number'
                />
              </div>
              <div className='sm:col-span-3'>
                <TextInput
                  name='companyEmail'
                  type='text'
                  placeholder='Email'
                  label='Company Email'
                />
              </div>
            </div>
          </div>
          <div className='pt-8'>
            <div>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                Socialmedia Links
              </h3>
            </div>
            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
              <div className='sm:col-span-3'>
                <TextInput
                  icon={
                    <svg
                      width='19'
                      height='19'
                      viewBox='0 0 19 19'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M16.2083 0.875C16.7167 0.875 17.2042 1.07693 17.5636 1.43638C17.9231 1.79582 18.125 2.28333 18.125 2.79167V16.2083C18.125 16.7167 17.9231 17.2042 17.5636 17.5636C17.2042 17.9231 16.7167 18.125 16.2083 18.125H2.79167C2.28333 18.125 1.79582 17.9231 1.43638 17.5636C1.07693 17.2042 0.875 16.7167 0.875 16.2083V2.79167C0.875 2.28333 1.07693 1.79582 1.43638 1.43638C1.79582 1.07693 2.28333 0.875 2.79167 0.875H16.2083ZM15.7292 15.7292V10.65C15.7292 9.82142 15.4 9.02678 14.8141 8.44088C14.2282 7.85499 13.4336 7.52583 12.605 7.52583C11.7904 7.52583 10.8417 8.02417 10.3817 8.77167V7.70792H7.70792V15.7292H10.3817V11.0046C10.3817 10.2667 10.9758 9.66292 11.7137 9.66292C12.0696 9.66292 12.4108 9.80427 12.6625 10.0559C12.9141 10.3075 13.0554 10.6488 13.0554 11.0046V15.7292H15.7292ZM4.59333 6.20333C5.02033 6.20333 5.42984 6.03371 5.73178 5.73178C6.03371 5.42984 6.20333 5.02033 6.20333 4.59333C6.20333 3.70208 5.48458 2.97375 4.59333 2.97375C4.16379 2.97375 3.75185 3.14438 3.44811 3.44811C3.14438 3.75185 2.97375 4.16379 2.97375 4.59333C2.97375 5.48458 3.70208 6.20333 4.59333 6.20333ZM5.92542 15.7292V7.70792H3.27083V15.7292H5.92542Z'
                        fill='#171717'
                      />
                    </svg>
                  }
                  name='socialMediaHandles.linkedIn'
                  type='text'
                  placeholder='LinkedIn'
                  label='LinkedIn URL'
                />
              </div>
              <div className='sm:col-span-3'>
                <TextInput
                  icon={
                    <svg
                      width='21'
                      height='18'
                      viewBox='0 0 21 18'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M20.5249 2.75065C19.787 3.08607 18.9916 3.30648 18.1674 3.4119C19.0107 2.90398 19.6624 2.09898 19.9691 1.13107C19.1736 1.61023 18.292 1.94565 17.3624 2.13732C16.6053 1.31315 15.5416 0.833984 14.3341 0.833984C12.082 0.833984 10.242 2.67398 10.242 4.94523C10.242 5.27107 10.2803 5.58732 10.3474 5.8844C6.93573 5.7119 3.89781 4.07315 1.87573 1.59107C1.52115 2.19482 1.3199 2.90398 1.3199 3.65148C1.3199 5.0794 2.03865 6.3444 3.15031 7.06315C2.4699 7.06315 1.8374 6.87148 1.28156 6.58398C1.28156 6.58398 1.28156 6.58398 1.28156 6.61273C1.28156 8.60607 2.6999 10.2736 4.57823 10.6473C4.23323 10.7432 3.86906 10.7911 3.49531 10.7911C3.23656 10.7911 2.97781 10.7623 2.72865 10.7144C3.24615 12.334 4.75073 13.5415 6.56198 13.5702C5.16281 14.6819 3.3899 15.3336 1.45406 15.3336C1.12823 15.3336 0.802396 15.3144 0.476562 15.2761C2.2974 16.4452 4.46323 17.1257 6.7824 17.1257C14.3341 17.1257 18.4836 10.8582 18.4836 5.4244C18.4836 5.24232 18.4836 5.06982 18.4741 4.88773C19.2791 4.31273 19.9691 3.5844 20.5249 2.75065Z'
                        fill='#171717'
                      />
                    </svg>
                  }
                  name='socialMediaHandles.twitter'
                  type='text'
                  placeholder='Twitter'
                  label='Twitter URL'
                />
              </div>
              <div className='sm:col-span-3'>
                <TextInput
                  icon={
                    <svg
                      width='20'
                      height='21'
                      viewBox='0 0 20 21'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M5.8 0.916016H14.2C17.4 0.916016 20 3.40768 20 6.47435V14.5243C20 15.9985 19.3889 17.4123 18.3012 18.4547C17.2135 19.4971 15.7383 20.0827 14.2 20.0827H5.8C2.6 20.0827 0 17.591 0 14.5243V6.47435C0 5.00019 0.61107 3.5864 1.69878 2.54401C2.78649 1.50162 4.26174 0.916016 5.8 0.916016ZM5.6 2.83268C4.64522 2.83268 3.72955 3.19616 3.05442 3.84316C2.37928 4.49016 2 5.36769 2 6.28268V14.716C2 16.6231 3.61 18.166 5.6 18.166H14.4C15.3548 18.166 16.2705 17.8025 16.9456 17.1555C17.6207 16.5085 18 15.631 18 14.716V6.28268C18 4.3756 16.39 2.83268 14.4 2.83268H5.6ZM15.25 4.27018C15.5815 4.27018 15.8995 4.39639 16.1339 4.62104C16.3683 4.8457 16.5 5.15039 16.5 5.4681C16.5 5.78581 16.3683 6.0905 16.1339 6.31515C15.8995 6.53981 15.5815 6.66602 15.25 6.66602C14.9185 6.66602 14.6005 6.53981 14.3661 6.31515C14.1317 6.0905 14 5.78581 14 5.4681C14 5.15039 14.1317 4.8457 14.3661 4.62104C14.6005 4.39639 14.9185 4.27018 15.25 4.27018ZM10 5.70768C11.3261 5.70768 12.5979 6.21252 13.5355 7.11113C14.4732 8.00974 15 9.22852 15 10.4993C15 11.7702 14.4732 12.989 13.5355 13.8876C12.5979 14.7862 11.3261 15.291 10 15.291C8.67392 15.291 7.40215 14.7862 6.46447 13.8876C5.52678 12.989 5 11.7702 5 10.4993C5 9.22852 5.52678 8.00974 6.46447 7.11113C7.40215 6.21252 8.67392 5.70768 10 5.70768ZM10 7.62435C9.20435 7.62435 8.44129 7.92725 7.87868 8.46642C7.31607 9.00558 7 9.73685 7 10.4993C7 11.2618 7.31607 11.9931 7.87868 12.5323C8.44129 13.0714 9.20435 13.3743 10 13.3743C10.7956 13.3743 11.5587 13.0714 12.1213 12.5323C12.6839 11.9931 13 11.2618 13 10.4993C13 9.73685 12.6839 9.00558 12.1213 8.46642C11.5587 7.92725 10.7956 7.62435 10 7.62435Z'
                        fill='#171717'
                      />
                    </svg>
                  }
                  name='socialMediaHandles.instagram'
                  type='text'
                  placeholder='Instagram'
                  label='Instagram URL'
                />
              </div>
              <div className='sm:col-span-3'>
                <TextInput
                  icon={
                    <svg
                      width='21'
                      height='21'
                      viewBox='0 0 21 21'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10.5013 0.955078C5.23047 0.955078 0.917969 5.258 0.917969 10.5576C0.917969 15.3492 4.42547 19.3263 9.0063 20.0451V13.3367H6.57214V10.5576H9.0063V8.43966C9.0063 6.03425 10.4342 4.71174 12.6288 4.71174C13.6734 4.71174 14.7659 4.89383 14.7659 4.89383V7.26091H13.5584C12.3701 7.26091 11.9963 7.99883 11.9963 8.75591V10.5576H14.6605L14.2292 13.3367H11.9963V20.0451C14.2546 19.6884 16.3109 18.5362 17.7942 16.7964C19.2774 15.0566 20.0898 12.8438 20.0846 10.5576C20.0846 5.258 15.7721 0.955078 10.5013 0.955078Z'
                        fill='#171717'
                      />
                    </svg>
                  }
                  name='socialMediaHandles.facebook'
                  type='text'
                  placeholder='Facebook'
                  label='Facebook URL'
                />
              </div>
              <div className='sm:col-span-3'>
                <TextInput
                  icon={
                    <svg
                      width='19'
                      height='15'
                      viewBox='0 0 19 15'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.75 10.3743L12.2912 7.49935L7.75 4.62435V10.3743ZM17.865 2.8706C17.9788 3.32102 18.0575 3.92477 18.11 4.69143C18.1712 5.4581 18.1975 6.11935 18.1975 6.69435L18.25 7.49935C18.25 9.5981 18.11 11.141 17.865 12.1281C17.6462 12.9906 17.1388 13.5464 16.3512 13.786C15.94 13.9106 15.1875 13.9969 14.0325 14.0543C12.895 14.1214 11.8538 14.1502 10.8913 14.1502L9.5 14.2077C5.83375 14.2077 3.55 14.0543 2.64875 13.786C1.86125 13.5464 1.35375 12.9906 1.135 12.1281C1.02125 11.6777 0.9425 11.0739 0.89 10.3073C0.82875 9.5406 0.8025 8.87935 0.8025 8.30435L0.75 7.49935C0.75 5.4006 0.89 3.85768 1.135 2.8706C1.35375 2.0081 1.86125 1.45227 2.64875 1.21268C3.06 1.0881 3.8125 1.00185 4.9675 0.944349C6.105 0.877265 7.14625 0.848516 8.10875 0.848516L9.5 0.791016C13.1663 0.791016 15.45 0.944349 16.3512 1.21268C17.1388 1.45227 17.6462 2.0081 17.865 2.8706Z'
                        fill='#171717'
                      />
                    </svg>
                  }
                  name='socialMediaHandles.youtube'
                  type='text'
                  placeholder='YouTube'
                  label='YouTube URL'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='pt-5'>
          <div className='flex justify-end gap-3'>
            <button
              onClick={() => {
                navigate('/employers');
              }}
              type='button'
              className='rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
            >
              Cancel
            </button>

            <button
              disabled={isSubmitting}
              type='submit'
              className={saveButtonStyle}
            >
              {isSubmitting ? 'Updating' : 'Save'}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}
