import { PlusIcon } from '@heroicons/react/24/outline';
import { buttonStyle } from 'global/helper';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className='sm:flex sm:items-center mb-8'>
      <div className='sm:flex-auto'>
        <h1 className='text-xl font-semibold text-gray-900'>Employers</h1>
        <p className='mt-2 text-sm text-gray-700'>
          A list of all the employers in your account
        </p>
      </div>
      <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
        <Link to='/employers/add'>
          <button type='button' className={buttonStyle}>
            <PlusIcon className=' className="-ml-1 mr-2 h-5 w-5"' />
            Add Employer
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Header;
