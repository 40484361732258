import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPublicApplications: builder.query<any, any>({
      query: (params) => {
        return {
          url: '/placement-application/public',
          params: params,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['PublicApplications'],
    }),
    getPublicUser: builder.query<any, any>({
      query: (params) => {
        return {
          url: '/user/public',
          params: params,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['PublicUser'],
    }),
  }),
});

export const { useGetPublicApplicationsQuery, useGetPublicUserQuery } =
  extendedApiSlice;
