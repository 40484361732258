import LoginForm from 'components/auth/LoginForm';

const Index = () => {
  return (
    <div>
      <LoginForm />
    </div>
  );
};

export default Index;
