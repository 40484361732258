import Loading from 'layout/Loading';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetPublicApplicationsQuery } from './openSlice';
import ShowApplications from './ShowApplications';

const jwt = require('jsonwebtoken');

const JWT_PUBLIC_KEY = process.env.REACT_APP_JWT_PUBLIC_KEY;

const RoleApplications = () => {
  let { jwttoken } = useParams();
  const PUBLIC_KEY = JWT_PUBLIC_KEY?.replace(/\\n/g, '\n');
  let jwtData = { placementRoleId: '' };
  try {
    jwtData = jwt.verify(jwttoken, PUBLIC_KEY);
  } catch (e) {
    console.log(e);
  }

  const placementRoleId = jwtData?.placementRoleId;
  const { isLoading, data, isError, isSuccess } = useGetPublicApplicationsQuery(
    { placementRoleId }
  );

  return (
    <div className='p-8'>
      {isLoading && <Loading />}
      {isError && (
        <div className='m-12 text-center font-bold text-2xl'>Invalid Url </div>
      )}

      {isSuccess && (
        <div>
          <ShowApplications data={data} />
        </div>
      )}
    </div>
  );
};

export default RoleApplications;
