import AllRoles from 'components/roles/AllRoles';
import NoRoles from 'components/roles/NoRoles';
import Error from 'layout/Error';
import Loading from 'layout/Loading';
import { useGetRolesQuery } from './RoleSlice';

const Index = () => {
  const { data, isLoading, isSuccess, isError } = useGetRolesQuery({
    limit: 0,
  });
  return (
    <>
      {isLoading && <Loading />}
      {isError && <Error />}
      {isSuccess &&
        (data.length ? (
          <div>
            <AllRoles data={data} />
          </div>
        ) : (
          <NoRoles />
        ))}
    </>
  );
};

export default Index;
