import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { Formik, FormikErrors, useFormikContext } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RoleType } from './types';
import RoleForm from './RoleForm';
import { useAddRoleMutation } from 'features/roles/RoleSlice';
import { useNavigate, Link, useParams } from 'react-router-dom';

const AddRole = () => {
  const navigate = useNavigate();
  let { cycleid } = useParams();
  const pages = [
    { name: 'Placement Cycle', href: '/cycle', current: false },
    {
      name: `Add Role`,
      href: `/cycle/view/${cycleid}/roles/add`,
      current: true,
    },
  ];
  const initialValues = {
    title: '',
    placementCompanyId: '',
    placementCycleId: cycleid || '',
    domain: '',
    skills: [''],
    roleType: 'Internship',
    workCulture: 'On-site',
    educationQualification: 'Graduation',
    experienceLevel: 'Fresher',
    totalOpening: '',
    payRange: '',
    disclosedSalary: false,
    roleDescription: '',
    perks: {},
    stages: [''],
    status: 'Active',
  };

  const ShowErrorsInToast = () => {
    // Grab values and submitForm from context
    const {
      errors,
      isSubmitting,
    }: {
      errors: FormikErrors<RoleType>;
      isSubmitting: boolean;
    } = useFormikContext();

    useEffect(() => {
      if (errors && isSubmitting) {
        //First dismiss all toasts
        toast.dismiss();
        //Show errors in toast
        Object.values(errors).forEach((value: any) => {
          toast.error(value);
        });
      }
    }, [errors, isSubmitting]);

    return null;
  };

  const [addRole] = useAddRoleMutation();

  return (
    <div className='flex flex-col'>
      <nav className='flex mb-4' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to='/' className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
                <Link
                  to={page.href}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      {/* <Summary summaryData={summaryData} /> */}
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          title: Yup.string().required('Name is a required field.'),
          placementCycleId: Yup.string().required(
            'Placement Cycle is a required field.'
          ),
          payRange: Yup.number().required().min(0).max(100),
          domain: Yup.string().required('Domain is a required'),
          placementCompanyId: Yup.string().required('Company is a required'),
          totalOpening: Yup.number().required().min(0).max(100),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          addRole(values)
            .unwrap()
            .then((res: any) =>
              navigate('/cycle/view/' + values.placementCycleId)
            )
            .catch(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <>
            <ShowErrorsInToast />
            <div>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                Add a new role
              </h3>
              <p className='mt-1 text-sm text-gray-600'>
                You will be able to add role details for a particular placement
                cycle below
              </p>
            </div>
            <RoleForm values={values} isSubmitting={isSubmitting} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default AddRole;
