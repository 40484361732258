import React, { useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { useField, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';

const TextInput = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div>
        <label htmlFor={label} className='sr-only'>
          {label}
        </label>
        <input
          {...field}
          {...props}
          className='relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primary-lighter focus:outline-none focus:ring-primary-lighter sm:text-sm'
        />
        {meta.touched && meta.error ? (
          <div className='text-sm text-red-500'>{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

const ResetForm = ({ setCodeSent, setEnteredEmail, setResetPage }: any) => {
  const [feedbackMessage, setFeedbackMessage] = useState('');
  return (
    <div>
      <div>
        <div className='flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
          <div className='w-full max-w-md space-y-8'>
            <div>
              <img
                className='mx-auto h-12 w-auto'
                src='https://res.cloudinary.com/belong/image/upload/v1650621217/uploaded_resources/logo_gqty7l.png'
                alt='Your Company'
              />
              <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
                Reset Your Password!
              </h2>
              <div>
                Please click on Reset Password button to send a confirmation
                code to your email address and create a new password for your
                account.
              </div>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={{
                email: '',
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email('Email must be a valid email address')
                  .required('Email is required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  await Auth.forgotPassword(values.email);
                  setCodeSent(true);
                  setEnteredEmail(values.email);
                } catch (error: any) {
                  setFeedbackMessage(error.messaage);
                }
              }}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form className='mt-8 space-y-6'>
                  <div className='-space-y-px rounded-md shadow-sm'>
                    <TextInput
                      label='Email address'
                      name='email'
                      type='text'
                      placeholder='Email'
                    />
                  </div>

                  <div>
                    <button
                      disabled={isSubmitting}
                      type='submit'
                      className='group relative flex w-full justify-center rounded-md border border-transparent bg-primary-light py-2 px-4 text-sm font-medium text-white hover:bg-primary focus:outline-none'
                    >
                      <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                        <LockClosedIcon
                          className='h-5 w-5 text-primary group-hover:text-white'
                          aria-hidden='true'
                        />
                      </span>
                      Send Code
                    </button>
                  </div>

                  <div>{feedbackMessage} </div>
                </Form>
              )}
            </Formik>
            <div className='text-sm flex justify-end'>
              <button
                onClick={() => setResetPage(false)}
                type='button'
                className='font-medium text-primary hover:text-primary-light'
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetForm;
