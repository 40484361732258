import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmployers: builder.query<any, any>({
      query: (slug) => {
        return {
          url: '/placement-company',
          params: slug,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Employers'],
    }),

    addEmployer: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/placement-company`,
          method: 'POST',
          body,
        };
      },
    }),
    updateEmployer: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/placement-company`,
          method: 'PUT',
          body,
        };
      },
      // Invalidates all queries that subscribe to the `Partner`.
      invalidatesTags: ['Employers'],
    }),
  }),
});

export const {
  useGetEmployersQuery,
  useAddEmployerMutation,
  useUpdateEmployerMutation,
} = extendedApiSlice;
