import React, { useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { axiosUpload } from 'config/Axios';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';

const EmployerLogo = ({
  values,
  setFieldValue,
}: {
  values: any;
  setFieldValue: any;
}) => {
  const [edit, setEdit] = useState(!values.companyLogo);
  const [errorMessage, setErrorMessage] = useState('');

  async function uploadFile(files: any) {
    setErrorMessage('');
    if (files.size > 2200000) {
      setErrorMessage('file must be less then 2 MB');
    } else {
      const formData = new FormData();
      formData.append('file', files);
      const result = await axiosUpload.post(
        '/aws-upload/partner-logo',
        formData
      );
      if (result.status === 200) {
        setFieldValue('companyLogo', result.data.publicUrl);
        setEdit(false);
      } else {
        setErrorMessage('Please try after some time');
      }
    }
  }
  return (
    <div>
      <div className='text-sm'> Employer Logo </div>
      <div className='flex justify-between'>
        {values.companyLogo && (
          <img
            className='h-10 object-cover'
            alt='logo'
            src={UPLOAD_OR_DOWNLOAD_URL + '/' + values.companyLogo}
          />
        )}
        {!edit && (
          <button type='button' onClick={() => setEdit(true)}>
            <PencilSquareIcon className='w-6 text-primary' />
          </button>
        )}
      </div>
      {edit && (
        <div className='mt-4'>
          {' '}
          <input
            type='file'
            accept='image/png, image/jpeg'
            onChange={(e: any) => {
              const file = e.target.files[0];
              uploadFile(file);
            }}
          />
        </div>
      )}
      <div className='text-center m-4'> {errorMessage}</div>
    </div>
  );
};

export default EmployerLogo;
