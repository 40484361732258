import { useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import AddMember from './AddMember';
import AllTeamBody from './AllTeamBody';

const AllTeamMembers = ({ data }: any) => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  function closeAddModal() {
    setIsAddOpen(false);
  }

  function openAddModal() {
    setIsAddOpen(true);
  }

  return (
    <>
      <div className='flex flex-col'>
        <div className='sm:flex sm:items-center mb-8'>
          <div className='sm:flex-auto'>
            <h1 className='text-xl font-semibold text-gray-900'>Users</h1>
            <p className='mt-2 text-sm text-gray-700'>
              A list of all the users in your account including their name,
              title, email and role.
            </p>
          </div>
          <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
            <button
              onClick={openAddModal}
              type='button'
              className='inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium text-primary border-primary shadow-sm hover:bg-primary-lighter focus:bg-primary focus:text-white focus:outline-none sm:w-auto'
            >
              <PlusIcon className=' className="-ml-1 mr-2 h-5 w-5"' />
              Add Team Member
            </button>
          </div>
        </div>
        <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8'>
          <div className='inline-block  min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='shadow ring-1 ring-black ring-opacity-5'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                    >
                      Name
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Email
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Role
                    </th>

                    <th
                      scope='col'
                      className='relative py-3.5 pl-3 pr-4 sm:pr-6'
                    >
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {data.map((team: any) => {
                    return <AllTeamBody key={team._id} team={team} />;
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <AddMember isOpen={isAddOpen} closeAddModal={closeAddModal} />
    </>
  );
};

export default AllTeamMembers;
