import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { Formik, FormikErrors, useFormikContext } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CycleType } from './types';
import CycleForm from './CycleForm';
import { Link, useNavigate } from 'react-router-dom';
import { useAddCycleMutation } from 'features/cycle/CycleSlice';

const pages = [
  { name: 'Placement Cycle', href: '/cycle', current: false },
  { name: 'Add Placement Cycle', href: '/cycle/add', current: true },
];
const AddCycle = () => {
  const navigate = useNavigate();

  const initialValues = {
    name: '',
    startDate: '',
    endDate: '',
    eligibilityCriteria: {
      minGrade: '',
      maxBacklog: '',
    },
    streams: [],
    graduationYear: [],
  };

  const ShowErrorsInToast = () => {
    // Grab values and submitForm from context
    const {
      errors,
      isSubmitting,
    }: {
      errors: FormikErrors<CycleType>;
      isSubmitting: boolean;
    } = useFormikContext();

    useEffect(() => {
      if (errors && isSubmitting) {
        //First dismiss all toasts
        toast.dismiss();
        //Show errors in toast
        Object.values(errors).forEach((value: any) => {
          toast.error(value);
        });
      }
    }, [errors, isSubmitting]);

    return null;
  };

  const [addCycle] = useAddCycleMutation();

  return (
    <div className='flex flex-col'>
      <nav className='flex mb-4' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to='/' className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
                <Link
                  to={page.href}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>

      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          name: Yup.string().required(
            'Placement Cycle Name is a required field.'
          ),
          startDate: Yup.string().required('Start Date is a required field.'),
          endDate: Yup.string().required('End Date is a required field.'),
          eligibilityCriteria: Yup.object().shape({
            minGrade: Yup.number().required('Grade Field is Required'),
            maxBacklog: Yup.number().required('Backlog Field is Required'),
          }),
          graduationYear: Yup.array().min(1),
          streams: Yup.array().min(1),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          addCycle(values)
            .unwrap()
            .then((res: any) => navigate('/cycle'));
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <>
            <ShowErrorsInToast />
            <CycleForm
              values={values}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default AddCycle;
