import DashboardLayout from 'layout/DashboardLayout';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from 'features/home';
import Students from 'features/students';
import Auth from 'features/auth';
import TeamTable from 'features/team';
import Onboarding from 'features/onboarding';
import Employers from 'features/employers';
import AddEmployer from 'components/employers/AddEmployer';
import Roles from 'features/roles';
import AMPLIFY_CONFIG from 'config/AmplifyConfig';
import { Amplify } from 'aws-amplify';
import PrivateRoute from './PrivateRoute';
import AddRole from 'components/roles/AddRole';
import EditEmployer from 'components/employers/EditEmployer';
import EditRole from 'components/roles/EditRole';
import PlacementCycle from 'features/cycle';
import AddCycle from 'components/cycle/AddCycle';
import EditCycle from 'components/cycle/EditCycle';
import RolePage from 'components/roles/RolePage';
import ViewStudent from 'features/students/ViewStudent';
import Reports from 'features/reports';
import ViewCycle from 'components/cycle/ViewCycle';
import RoleApplications from 'components/openPage/RoleApplications';
import EmployerRoles from 'components/employers/EmployerRoles';
import Announcements from 'features/announcements';
import AddAnnouncement from 'components/announcements/AddAnnouncement';
import EditAnnouncement from 'components/announcements/EditAnnouncement';
import ViewAnoouncement from 'components/announcements/ViewAnnouncement';

const Index = () => {
  Amplify.configure(AMPLIFY_CONFIG);
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path='/login' element={<Auth />} />
        <Route path='/application/:jwttoken' element={<RoleApplications />} />
        {/* Private Routes */}
        <Route element={<PrivateRoute />}>
          <Route path='/' element={<DashboardLayout />}>
            <Route index element={<Home />} />
            <Route path='students'>
              <Route index element={<Students />} />
              <Route path='view/:slug' element={<ViewStudent />} />
            </Route>
            <Route path='team' element={<TeamTable />} />
            <Route path='onboarding' element={<Onboarding />} />
            <Route path='reports' element={<Reports />} />
            <Route path='employers'>
              <Route index element={<Employers />} />
              <Route path='add' element={<AddEmployer />} />
              <Route path='edit/:slug' element={<EditEmployer />} />
              <Route path='roles/:slug' element={<EmployerRoles />} />
            </Route>
            <Route path='roles'>
              <Route index element={<Roles />} />
              <Route path='add' element={<AddRole />} />
              <Route path='edit/:slug' element={<EditRole />} />
              <Route path=':slug' element={<RolePage />} />
            </Route>
            <Route path='announcements'>
              <Route index element={<Announcements />} />
              <Route path='add' element={<AddAnnouncement />} />
              <Route path='view/:slug' element={<ViewAnoouncement />} />
              <Route path='edit/:slug' element={<EditAnnouncement />} />
            </Route>
            <Route path='cycle'>
              <Route index element={<PlacementCycle />} />
              <Route path='add' element={<AddCycle />} />
              <Route path='view/:cycleid' element={<ViewCycle />} />
              <Route path='edit/:slug' element={<EditCycle />} />
              <Route
                path='view/:cycleid/roles/edit/:roleid'
                element={<EditRole />}
              />
              <Route path='view/:cycleid/roles/add' element={<AddRole />} />
              <Route
                path='view/:cycleid/roles/:roleid'
                element={<RolePage />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Index;
