import {
  ArrowUpOnSquareIcon,
  ClipboardDocumentCheckIcon,
} from '@heroicons/react/24/outline';
import RolesPopup from 'components/roles/RolesPopup';
import { buttonStyle } from 'global/helper';
import { useState } from 'react';
import { CSVLink } from 'react-csv';

const StudentActions = ({
  data,
  selectedPeople,
}: {
  data: any;
  selectedPeople: any;
}) => {
  let [isOpen, setIsOpen] = useState(false);
  const num = selectedPeople.length;

  //Filter data based on selected people

  const exportPeople = data.filter((item: any) => {
    return selectedPeople.includes(item._id);
  });

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Stream', key: 'stream' },
    { label: 'Graduation Year', key: 'graduationYear' },
    { label: 'Course', key: 'course' },
  ];
  return (
    <div className=' bg-gray-50 sm:rounded-lg px-4 py-5 sm:p-6'>
      <p className=' font-medium leading-6 text-gray-900 mb-4'>
        {`${num} ${num > 1 ? 'Students' : 'Student'} Selected`}
      </p>
      <div className='space-x-8'>
        <CSVLink data={exportPeople} headers={headers} className={buttonStyle}>
          Export Data
          <ArrowUpOnSquareIcon
            className='ml-3 -mr-1 h-5 w-5'
            aria-hidden='true'
          />
        </CSVLink>
        <button
          type='button'
          className={buttonStyle}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Shortlist
          <ClipboardDocumentCheckIcon
            className='ml-3 -mr-1 h-5 w-5'
            aria-hidden='true'
          />
        </button>
      </div>
      <RolesPopup
        selectedPeople={selectedPeople}
        numApplications={num}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default StudentActions;
