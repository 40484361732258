import React, { useState } from 'react';
import DeleteMember from './DeleteMember';
import EditMember from './EditMember';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';

const AllTeamBody = ({ team }: { team: any }) => {
  const isAdmin = team.roles.includes('Partner Admin');
  const [isEditOpen, setIsEditOpen] = useState(false);
  function closeEditModal() {
    setIsEditOpen(false);
  }
  function openEditModal() {
    setIsEditOpen(true);
  }
  const [isDeletetOpen, setIsDeletetOpen] = useState(false);
  function closeDeletetModal() {
    setIsDeletetOpen(false);
  }
  function openDeletetModal() {
    setIsDeletetOpen(true);
  }
  return (
    <>
      <tr>
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
          <div className='flex items-center'>
            <div className='font-medium text-gray-900'>
              {team.name || team.firstName}
            </div>
          </div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          {team.email}
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          {isAdmin ? 'Admin' : 'Team Member'}
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <button onClick={() => openEditModal()}>
            <PencilSquareIcon className='w-6 text-primary' />
          </button>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <button onClick={() => openDeletetModal()}>
            <TrashIcon className='w-6 text-red-500' />
          </button>
        </td>
      </tr>
      <EditMember
        isOpen={isEditOpen}
        closeAddModal={closeEditModal}
        adminData={team}
      />
      <DeleteMember
        isOpen={isDeletetOpen}
        closeModal={closeDeletetModal}
        adminData={team}
      />
    </>
  );
};

export default AllTeamBody;
