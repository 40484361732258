import React from 'react';
import AllApplications from '../../components/applications/AllApplications';
import { useGetApplicationsQuery } from '../../components/applications/applicationsSlice';
import Loading from '../../layout/Loading';

const StudentApplications = ({ userId }: { userId?: string }) => {
  const appData = useGetApplicationsQuery({
    limit: 0,
    userId: userId,
  });

  return (
    <>
      {appData.isLoading && <Loading />}
      {appData.isSuccess && (
        <div>
          <AllApplications data={appData.data} />
        </div>
      )}
    </>
  );
};

export default StudentApplications;
