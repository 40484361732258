import React from 'react';
import { useGetCycleQuery } from './CycleSlice';
import AllCycle from 'components/cycle/AllCycle';
import NoCycle from 'components/cycle/NoCycle';
import Loading from 'layout/Loading';
import Error from 'layout/Error';

const Index = () => {
  const { data, isLoading, isSuccess, isError } = useGetCycleQuery({
    limit: 0,
  });
  return (
    <>
      {isLoading && <Loading />}
      {isError && <Error />}
      {isSuccess &&
        (data.length ? (
          <div>
            <AllCycle data={data} />
          </div>
        ) : (
          <NoCycle />
        ))}
    </>
  );
};

export default Index;
