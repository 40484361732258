import { useGetBasicOverviewQuery } from 'features/home/homeSlice';
import Error from 'layout/Error';
import Loading from 'layout/Loading';

export default function Data() {
  const { data, isLoading, isSuccess, isError } = useGetBasicOverviewQuery({});
  return (
    <>
      {isLoading && <Loading />}
      {isError && <Error />}
      {isSuccess && (
        <div>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>
            Dashboard
          </h3>

          <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3'>
            <div className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
              <dt className='truncate text-sm font-medium text-gray-500'>
                Users
              </dt>
              <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
                {data.totalUser}
              </dd>
            </div>
            <div className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
              <dt className='truncate text-sm font-medium text-gray-500'>
                Employers
              </dt>
              <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
                {data.totalEmployers}
              </dd>
            </div>
            <div className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
              <dt className='truncate text-sm font-medium text-gray-500'>
                Roles
              </dt>
              <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
                {data.totalRoles}
              </dd>
            </div>
          </dl>
        </div>
      )}
    </>
  );
}
