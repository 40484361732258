import { useEffect, useState } from 'react';
import {
  ErrorMessage,
  Field,
  Formik,
  Form,
  FormikErrors,
  useFormikContext,
} from 'formik';
import * as Yup from 'yup';
import { Switch } from '@headlessui/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorMessageStyle } from 'global/helper';
import {
  useGetPartnerQuery,
  useUpdatePartnerMutation,
} from 'features/PartnerSlice';
import { useNavigate } from 'react-router-dom';
import PartnerLogo from './PartnerLogo';

const ScoringFormatDetails = ({
  data,
  setView,
  handleSwitch,
}: {
  data: any;
  setView: (value: number) => void;
  handleSwitch: any;
}) => {
  const { data: partnerData, isSuccess } = useGetPartnerQuery();
  const gpa = partnerData?.partnerId?.maxGpa || 0;

  const [percentageEnabled, setPercentageEnabled] = useState(gpa === 100);
  const [updatePartner] = useUpdatePartnerMutation();

  const initialValues = {
    maxGpa: percentageEnabled ? 100 : gpa,
    logoUrl: partnerData?.partnerId?.logoUrl || '',
  };
  type FormValues = {
    maxGpa: number;
    logoUrl: string;
  };

  const ShowErrorsInToast = () => {
    // Grab values and submitForm from context
    const {
      errors,
      isSubmitting,
    }: {
      errors: FormikErrors<FormValues>;
      isSubmitting: boolean;
    } = useFormikContext();

    useEffect(() => {
      if (errors && isSubmitting) {
        //First dismiss all toasts
        toast.dismiss();
        //Show errors in toast
        Object.values(errors).forEach((value: any) => {
          toast.error(value);
        });
      }
    }, [errors, isSubmitting]);

    return null;
  };
  const navigate = useNavigate();
  const inputStyle =
    'mt-1 block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm';
  return (
    <>
      <ToastContainer />
      {isSuccess && data && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object({
            maxGpa: Yup.number().required('GPA is a required field'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSwitch({ type: 'UPDATE', values });
            updatePartner({ ...data, ...values })
              .unwrap()
              .then((res) => {
                navigate('/');
              });
            setSubmitting(false);
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <>
              <ShowErrorsInToast />
              <div className='px-4 py-5 sm:p-6'>
                <Form>
                  <div className='grid grid-cols-6 gap-4'>
                    <div className='col-span-6 sm:col-span-3'>
                      <div className='flex gap-4 items-center'>
                        <label
                          htmlFor='firstName'
                          className='block text-sm font-medium text-neutral-900 md:font-bold'
                        >
                          Scoring format used
                        </label>
                        <div>
                          <Switch
                            checked={percentageEnabled}
                            onChange={setPercentageEnabled}
                            className={`${
                              percentageEnabled ? 'bg-teal-900' : 'bg-teal-700'
                            }
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                          >
                            <span className='sr-only'>Use setting</span>
                            <span
                              aria-hidden='true'
                              className={`${
                                percentageEnabled
                                  ? 'translate-x-9'
                                  : 'translate-x-0'
                              }
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                            />
                          </Switch>
                        </div>
                      </div>

                      <div className='py-8'>
                        {percentageEnabled ? 'Percentage' : 'Maximum CGPA'}
                        {percentageEnabled ? (
                          <p>X / 100%</p>
                        ) : (
                          <Field
                            className={inputStyle}
                            name='maxGpa'
                            type='number'
                            min='4'
                            max='10'
                          />
                        )}
                      </div>

                      <div className={errorMessageStyle}>
                        <ErrorMessage name='streams' />
                      </div>
                    </div>
                    <div className='col-span-6 sm:col-span-3'>
                      <PartnerLogo
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 gap-4 mt-4'>
                    <div className='col-span-12 text-right'>
                      <button
                        disabled={isSubmitting}
                        className='bg-primary px-8 py-2 text-white font-light  text-sm rounded'
                      >
                        {isSubmitting ? 'Updating' : 'Next'}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default ScoringFormatDetails;
