import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import Header from './Header';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { EyeIcon, PencilSquareIcon } from '@heroicons/react/24/outline';

const pages = [{ name: 'Placement Cycle', href: '/cycle', current: true }];

const AllCycle = ({ data }: any) => {
  return (
    <div className='flex flex-col'>
      <nav className='flex mb-4' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to='/' className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
                <Link
                  to={page.href}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <Header />
      <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8'>
        <div className='inline-block  min-w-full py-2 align-middle md:px-6 lg:px-8'>
          <div className='shadow ring-1 ring-black ring-opacity-5'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                  >
                    Name
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Start Date
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    End Date
                  </th>

                  <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                    <span className='sr-only'>Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {data.map((cycle: any) => (
                  <tr key={cycle._id}>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='flex items-center'>
                        <div className='font-medium text-gray-900'>
                          {cycle.name}
                        </div>
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {cycle.startDate &&
                        format(new Date(cycle.startDate), 'MMM u')}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {cycle.endDate &&
                        format(new Date(cycle.endDate), 'MMM u')}
                    </td>

                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex gap-4'>
                      <Link to={`/cycle/edit/${cycle._id}`}>
                        <PencilSquareIcon className='w-6' />
                      </Link>
                      <Link to={`/cycle/view/${cycle._id}`}>
                        <EyeIcon className='w-6' />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCycle;
