import AllStudents from './AllStudents';

const Index = () => {
  // const [tableView, setTableView] = useState(true);

  return (
    <>
      <div>
        {/* <StudentStatsBox /> */}
        <AllStudents />
        {/* <PersonalInfo /> */}
      </div>
    </>
  );
};

export default Index;
