import axios from 'axios';
import { store } from '../redux/store';

import { PREFIX } from './API_LINKS';

// export const axiosSignup = axios.create({
//   baseURL: PREFIX + '/api/v2/',
//   headers: { 'x-belong-api-token': store?.getState()?.auth?.token },
// });

export const axiosSignIn = axios.create({
  baseURL: PREFIX + '/api/v2/signin',
});

export const axiosApi = axios.create({
  baseURL: PREFIX + '/api/v2',
});

export const axiosUpload = axios.create({
  baseURL: PREFIX + '/api/v2',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

// Add a request interceptor
axiosUpload.interceptors.request.use(function (config) {
  config.headers['x-belong-api-token'] = store?.getState()?.auth.token;
  return config;
});

axiosApi.interceptors.request.use(function (config) {
  config.headers['x-belong-api-token'] = store?.getState()?.auth.token;
  return config;
});
