import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { PREFIX } from '../config/API_LINKS';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: PREFIX + '/api/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth?.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('x-belong-api-token', `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    'User',
    'Partner',
    'Students',
    'Student',
    'Employers',
    'Industry',
    'Roles',
    'Cycle',
    'Applications',
    'TeamMembers',
    'PublicApplications',
    'PublicUser',
    'Announcement',
    'BasicOverview',
    'ApplicationOverview',
  ],
  endpoints: (builder) => ({}),
});
