import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { Formik, FormikErrors, useFormikContext } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RoleType } from './types';
import RoleForm from './RoleForm';
import {
  useUpdateRoleMutation,
  useGetRolesQuery,
} from 'features/roles/RoleSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from 'layout/Loading';

const EditRole = () => {
  const navigate = useNavigate();
  let { roleid, cycleid } = useParams();
  const { data, isSuccess, isLoading } = useGetRolesQuery({
    placementRoleId: roleid,
  });
  const pages = [
    { name: 'Placement Cycle', href: '/cycle', current: false },
    { name: 'Roles', href: `/cycle/view/${cycleid}`, current: false },
    {
      name: `Edit ${data ? data[0]?.title : ''} Role`,
      href: `/cycle/view/${cycleid}/roles/edit/${roleid}`,
      current: true,
    },
  ];
  const initial = isSuccess && data[0];
  const initialValues = {
    placementRoleId: initial?._id,
    title: initial?.title,
    placementCycleId: initial?.placementCycleId?._id,
    placementCompanyId: initial?.placementCompanyId?._id,
    domain: initial?.title,
    skills: initial?.skills || [''],
    roleType: initial?.roleType,
    workCulture: initial?.workCulture,
    educationQualification: initial?.educationQualification,
    experienceLevel: initial?.experienceLevel,
    totalOpening: initial?.totalOpening,
    payRange: initial?.payRange,
    disclosedSalary: initial?.disclosedSalary,
    roleDescription: initial?.roleDescription,
    perks: initial?.perks || {},
    stages: initial?.stages || [''],
    status: initial?.status || 'Active',
  };

  const ShowErrorsInToast = () => {
    // Grab values and submitForm from context
    const {
      errors,
      isSubmitting,
    }: {
      errors: FormikErrors<RoleType>;
      isSubmitting: boolean;
    } = useFormikContext();

    useEffect(() => {
      if (errors && isSubmitting) {
        //First dismiss all toasts
        toast.dismiss();
        //Show errors in toast
        Object.values(errors).forEach((value: any) => {
          toast.error(value);
        });
      }
    }, [errors, isSubmitting]);

    return null;
  };

  const [updateRole] = useUpdateRoleMutation();

  return (
    <>
      {isLoading && <Loading />}
      {initial && (
        <div className='flex flex-col'>
          <nav className='flex mb-4' aria-label='Breadcrumb'>
            <ol className='flex items-center space-x-4'>
              <li>
                <div>
                  <Link to='/' className='text-gray-400 hover:text-gray-500'>
                    <HomeIcon
                      className='h-5 w-5 flex-shrink-0'
                      aria-hidden='true'
                    />
                    <span className='sr-only'>Home</span>
                  </Link>
                </div>
              </li>
              {pages.map((page) => (
                <li key={page.name}>
                  <div className='flex items-center'>
                    <ChevronRightIcon
                      className='h-5 w-5 flex-shrink-0 text-gray-400'
                      aria-hidden='true'
                    />
                    <Link
                      to={page.href}
                      className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                      aria-current={page.current ? 'page' : undefined}
                    >
                      {page.name}
                    </Link>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
          {/* <Summary summaryData={summaryData} /> */}
          <ToastContainer />
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={Yup.object({
              title: Yup.string().required('First Name is a required field.'),
              placementCycleId: Yup.string().required(
                'Placement Cycle is a required field.'
              ),
              payRange: Yup.number().required().min(0).max(100),
              domain: Yup.string().required('Domain is a required'),
              placementCompanyId: Yup.string().required(
                'Company is a required'
              ),
              totalOpening: Yup.number().required().min(0).max(100),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              updateRole(values)
                .unwrap()
                .then(() => navigate(`/cycle/view/${values.placementCycleId}`));
            }}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <>
                <ShowErrorsInToast />
                <RoleForm values={values} isSubmitting={isSubmitting} />
              </>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default EditRole;
