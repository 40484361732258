import AllAnnouncements from 'components/announcements/AllAnnouncements';
import React from 'react';

const Index = () => {
  return (
    <div>
      <AllAnnouncements />
    </div>
  );
};

export default Index;
