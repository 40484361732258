import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStudent: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/partner/users-full',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Student'],
    }),
    getStudents: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/partner/users-basic',
          params: arg,
        };
      },
      transformResponse: (res: any) => res,
      providesTags: ['Students'],
    }),
    verifiyStudent: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/user/verify`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Students', 'Student'],
    }),
  }),
});

export const {
  useGetStudentsQuery,
  useGetStudentQuery,
  useVerifiyStudentMutation,
} = extendedApiSlice;
