const Header = () => {
  return (
    <div className='sm:flex sm:items-center mb-8'>
      <div className='sm:flex-auto'>
        <h1 className='text-xl font-semibold text-gray-900'>Students</h1>
        <p className='mt-2 text-sm text-gray-700'>
          A list of all the students in your account
        </p>
      </div>
    </div>
  );
};

export default Header;
