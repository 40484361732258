import BasicDetails from 'components/onboarding/BasicDetails';
import ScoringFormatDetails from 'components/onboarding/ScoringFormatDetails';
import CourseDetails from 'components/onboarding/CourseDetails';
import StreamDetails from 'components/onboarding/StreamDetails';
import { reducer } from 'global/helper';
import { useReducer, useState } from 'react';

const Index = () => {
  const initialData = {
    name: '',
    website: '',
    courses: [''],
    streams: [''],
    maxGpa: 100,
    logoUrl: '',
  };

  const [data, dispatch] = useReducer(reducer, initialData);
  const [view, setView] = useState(1);
  const handleSwitch = (item: string | string[]) => {
    dispatch({ type: 'UPDATE', item });
  };

  return (
    <div className='mx-auto max-w-3xl'>
      <h3 className='mt-6 text-center text-2xl font-semibold tracking-tight text-gray-900'>
        Welcome, Let's get you onboarded
      </h3>
      <div className='overflow-hidden bg-white shadow sm:rounded-lg mt-6'>
        <div className='px-4 py-5 sm:p-6'>
          <p className='text-sm text-gray-500'>Verify these details...</p>
        </div>
        {view === 1 && (
          <BasicDetails setView={setView} handleSwitch={handleSwitch} />
        )}
        {view === 2 && (
          <CourseDetails setView={setView} handleSwitch={handleSwitch} />
        )}
        {view === 3 && (
          <StreamDetails setView={setView} handleSwitch={handleSwitch} />
        )}
        {view === 4 && (
          <ScoringFormatDetails
            data={data}
            setView={setView}
            handleSwitch={handleSwitch}
          />
        )}
      </div>
    </div>
  );
};

export default Index;
