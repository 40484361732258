import { useState } from 'react';
import {
  useGetStudentQuery,
  useVerifiyStudentMutation,
} from 'features/students/PartnerStudents';
import Loading from 'layout/Loading';
import Stats from './Stats';
import StudentDescription from './StudentDescription';
import StudentEducation from './StudentEducation';
import StudentExperience from './StudentExperience';
import { buttonStyle } from 'global/helper';

const Index = ({ userId }: { userId: any }) => {
  const { isLoading, isSuccess, data } = useGetStudentQuery({ userId });
  const [verifiyStudent] = useVerifiyStudentMutation();
  const [loading, setLoading] = useState(false);
  const verifiyStudentFn = () => {
    setLoading(true);
    verifiyStudent({ userId })
      .unwrap()
      .then(() => setLoading(false));
  };
  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && data && (
        <div className='-mx-8 text-neutral-900'>
          <div className='flex justify-end m-1'>
            {!data.verified && (
              <button
                disabled={loading}
                onClick={() => verifiyStudentFn()}
                className={buttonStyle}
              >
                {loading ? 'Updating' : 'Mark as verified'}
              </button>
            )}
          </div>
          <StudentDescription data={data} />
          {data?.education?.length > 0 && <StudentEducation data={data} />}
          {data?.workExperience?.length > 0 && (
            <StudentExperience data={data} />
          )}
          <Stats data={data} />
        </div>
      )}
    </>
  );
};

export default Index;
