import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import AllApplications from 'components/applications/AllApplications';
import { useGetApplicationsQuery } from 'components/applications/applicationsSlice';
import NoApplications from 'components/applications/NoApplications';
import { useGetRolesQuery } from 'features/roles/RoleSlice';
import Error from 'layout/Error';
import Loading from 'layout/Loading';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Tabs from './Tabs';

const RolePage = () => {
  let { cycleid, roleid } = useParams();

  const [view, setView] = useState('All Applications');

  const {
    data = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetRolesQuery({
    limit: 0,
    placementRoleId: roleid,
  });

  const pages = [
    { name: 'Placement Cycles', href: '/cycle', current: false },
    {
      name: data[0]?.placementCycleId?.name,
      href: `/cycle/view/${cycleid}`,
      current: false,
    },
    {
      name: data[0]?.title,
      href: `/cycle/view/${cycleid}/roles/${roleid}`,
      current: true,
    },
  ];

  const appData = useGetApplicationsQuery({
    limit: 0,
    placementRoleId: roleid,
    currentStage: view === 'All Applications' ? '' : view,
  });

  const success = isSuccess && appData.isSuccess;
  const loading = isLoading || appData.isLoading;
  const fetching = appData.isFetching;

  return (
    <>
      {loading && <Loading />}
      {isError && <Error />}
      {success && (
        <div className='flex flex-col'>
          <nav className='flex mb-4' aria-label='Breadcrumb'>
            <ol className='flex items-center space-x-4'>
              <li>
                <div>
                  <Link to='/' className='text-gray-400 hover:text-gray-500'>
                    <HomeIcon
                      className='h-5 w-5 flex-shrink-0'
                      aria-hidden='true'
                    />
                    <span className='sr-only'>Home</span>
                  </Link>
                </div>
              </li>
              {pages.map((page) => (
                <li key={page.name}>
                  <div className='flex items-center'>
                    <ChevronRightIcon
                      className='h-5 w-5 flex-shrink-0 text-gray-400'
                      aria-hidden='true'
                    />
                    <Link
                      to={page.href}
                      className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                      aria-current={page.current ? 'page' : undefined}
                    >
                      {page.name}
                    </Link>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
          <div className='bg-gray-50 sm:rounded-lg px-4 py-5 sm:p-6'>
            <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium text-gray-500'>Role</dt>
                <dd className='mt-1 text-sm text-gray-900'>
                  {data[0]?.title} ({data[0].roleType})
                </dd>
              </div>
              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium text-gray-500'>Company</dt>
                <dd className='mt-1 text-sm text-gray-900'>
                  {data[0]?.placementCompanyId.name}
                </dd>
              </div>
            </dl>
          </div>

          <div className='mt-4'>
            <Tabs view={view} setView={setView} stages={data[0].stages || []} />
            <div className='mt-4'>
              {fetching ? (
                <Loading />
              ) : (
                <div>
                  <>
                    {appData.data?.length ? (
                      <AllApplications view={view} data={appData.data} />
                    ) : view === 'All Applications' ? (
                      <NoApplications />
                    ) : (
                      <p className='text-gray-500'>
                        No applicants at this stage yet
                      </p>
                    )}
                  </>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RolePage;
