import React from 'react';

const EditHeader = () => {
  return (
    <div>
      <h3 className='text-lg font-medium leading-6 text-gray-900'>
        Edit employer
      </h3>
      <p className='mt-1 text-sm text-gray-600'>
        You will be able to edit the employer information below
      </p>
    </div>
  );
};

export default EditHeader;
