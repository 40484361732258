import React from 'react';
import { useParams } from 'react-router-dom';

import jwt from 'jsonwebtoken';

const REACT_APP_JWT_PRIVATE_KEY = process.env.REACT_APP_JWT_PRIVATE_KEY;

const ShareButton = ({ view }) => {
  let { roleid } = useParams();
  const data = { placementRoleId: roleid };

  const PRIVATE_KEY = REACT_APP_JWT_PRIVATE_KEY?.replace(/\\n/g, '\n');

  // Convert 90 days to seconds
  const expiresIn = 90 * 24 * 60 * 60;

  const jwtToken = jwt.sign(data, PRIVATE_KEY, {
    algorithm: 'RS256',
    expiresIn,
  });

  const hostname = window.location.hostname || '';
  const firsturl = hostname.split('.')[0];
  let url = hostname;
  if (firsturl === 'localhost') {
    url = 'http://localhost:3000';
  }
  const finalUrl = url + '/application/' + jwtToken;
  async function copyToClipboard() {
    await navigator.clipboard.writeText(finalUrl);
    alert('Link Copied');
  }
  return (
    <div>
      {view === 'All Applications' && roleid && (
        <button
          onClick={() => {
            copyToClipboard();
          }}
        >
          Share Data
        </button>
      )}
    </div>
  );
};

export default ShareButton;
