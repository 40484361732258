import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCycle: builder.query<any, any>({
      query: (slug) => {
        return {
          url: '/placement-cycle',
          params: slug,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Cycle'],
    }),

    addCycle: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/placement-cycle`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Cycle'],
    }),
    updateCycle: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/placement-cycle`,
          method: 'PUT',
          body,
        };
      },
      // Invalidates all queries that subscribe to the `Partner`.
      invalidatesTags: ['Cycle'],
    }),
  }),
});

export const { useGetCycleQuery, useAddCycleMutation, useUpdateCycleMutation } =
  extendedApiSlice;
