import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

import { useDeleteTeamMembersMutation } from 'features/team/TeamSlice';

export default function DeleteMember({
  isOpen,
  closeModal,
  adminData,
}: {
  isOpen: boolean;
  closeModal: any;
  adminData: any;
}) {
  const [deleteTeam] = useDeleteTeamMembersMutation();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    Delete User
                  </Dialog.Title>
                  <div className='mt-2'>
                    <div className='font-semibold'>
                      Are you sure you want to remove{' '}
                      {adminData.name || adminData.firstName}?
                    </div>
                    <div className='mt-8 flex justify-between'>
                      <div>
                        <button
                          className='px-8 py-2 bg-primary-light rounded-lg'
                          onClick={() => closeModal()}
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <button
                          disabled={loading}
                          onClick={() => {
                            setLoading(true);
                            deleteTeam({ adminId: adminData._id })
                              .unwrap()
                              .then((res: any) => closeModal());
                          }}
                          className='px-8 py-2 bg-red-400 rounded-lg'
                        >
                          {loading ? 'Deleting' : 'Delete'}
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
