import AllEmployers from 'components/employers/AllEmployers';

const Index = () => {
  return (
    <>
      <AllEmployers />
    </>
  );
};

export default Index;
