import classNames from 'classnames';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';
import { Link } from 'react-router-dom';

const StudentBox = ({ data }: { data: any }) => {
  const isActive = false;
  const imageUrl = data?.imageUrl
    ? UPLOAD_OR_DOWNLOAD_URL + '/' + data?.imageUrl
    : 'https://res.cloudinary.com/belong/image/upload/v1660803220/uploaded_resources/am-a-19-year-old-multimedia-artist-student-from-manila--21_bk3itt.png';
  const skills = data?.skills || [];
  const degree = data?.education ? data?.education[0]?.degree : [];
  const cgpa = data?.education ? data?.education[0]?.grade : [];
  const workExperience = data?.workExperience?.length;
  return (
    <div
      className={classNames(
        'px-2 lg:px-4 pt-6 pb-4 space-y-6 cursor-pointer text-textColor-landing border-2 border-customGrey rounded-md',
        {
          'border-primary': isActive,
        }
      )}
    >
      <div className='flex space-x-4 items-center'>
        <img
          src={imageUrl}
          alt='studentPic'
          className={classNames('w-[72px] h-[72px] rounded-full grayscale-0', {
            'grayscale-0': isActive,
          })}
        />
        <div className='space-y-2'>
          <p className='font-semibold text-lg'>
            {data.firstName} {data.lastName}
          </p>
          <p>{data.title}</p>
        </div>
      </div>
      {/* div for text and level */}
      <div className='flex'>
        {/* div for text */}
        <div className='flex flex-col space-y-2 basis-1/2'>
          {degree ? (
            <div className='flex  space-x-2 items-center'>
              <img
                src='/images/icons/degreeIcon.png'
                alt='degreeicon'
                className='h-4 w-4 object-contain'
              />
              <p className='text-sm lg:text-base'>{degree}</p>
            </div>
          ) : null}
          {cgpa ? (
            <div className='flex  space-x-2 items-center'>
              <img
                src='/images/icons/cgpaIcon.png'
                alt='cgpaicon'
                className='h-4 w-4 object-contain'
              />
              <p className='text-sm lg:text-base'>{cgpa} CGPA</p>
            </div>
          ) : null}
          {workExperience ? (
            <div className='flex   space-x-2 items-center'>
              <img
                src='/images/icons/internshipIcon.png'
                alt='internshipicon'
                className='h-4 w-4 object-contain'
              />
              <p className='text-sm lg:text-base'>
                {workExperience} Internships
              </p>
            </div>
          ) : null}
        </div>
        {/* div for level */}
        <div className='flex flex-col space-y-2 basis-1/2'>
          {skills.map((skill: any) => {
            return (
              <div key={skill.title} className='bg-gray-200 h-5'>
                <div
                  className='bg-primary h-5 text-xs font-medium text-blue-100 text-center  leading-none rounded flex items-center justify-center'
                  style={{ width: `${skill.level * 10}%` }}
                >
                  {skill.title}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className='flex justify-between'>
        <Link
          to={`/students/view/${data._id}`}
          className='cursor-pointer underline  decoration-textColor-landing'
        >
          View Details
          <span className='sr-only'>, {data.firstName}</span>
        </Link>
      </div>
    </div>
  );
};

export default StudentBox;
