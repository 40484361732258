import { useGetCycleQuery } from 'features/cycle/CycleSlice';
import { useGetEmployersQuery } from 'features/employers/EmployerSlice';
import { FieldArray, Form } from 'formik';
import { saveButtonStyle } from 'global/helper';
import { Checkbox, Select, TextArea, TextInput } from 'layout/FormElements';
import { Link } from 'react-router-dom';

const placementRole = {
  roleTypes: {
    internship: 'Internship',
    fullTime: 'Full Time',
    partTime: 'Part Time',
    contract: 'Contract',
  },
  experienceLevels: {
    fresher: 'Fresher',
    zeroToOne: '0-1 Year',
    oneToThree: '1-3 Year',
    threeToSix: '3-6 Year',
    sixPlus: '6+ Year',
  },
  workCulture: {
    onsite: 'On-site',
    hybrid: 'Hybrid',
    remote: 'Remote',
  },
  educationQualification: {
    graduation: 'Graduation',
    masters: 'Masters',
  },
  payRange: {
    zeroToThree: '0 to 3 LPA',
    threeToFive: '3 to 5 LPA',
    fiveToSeven: '5 to 7 LPA',
    sevenToTen: '7 to 10 LPA',
    TenToFifteen: '10 to 15 LPA',
    fifteenPlus: '15+ LPA',
  },
  status: {
    active: 'Active',
    inactive: 'Inactive',
  },
};

export default function RoleForm({ values, isSubmitting }: any) {
  const { data: allCompany = [] } = useGetEmployersQuery({
    limit: 0,
  });
  const { data: allCycle = [], isSuccess: cycleSuccess } = useGetCycleQuery({
    limit: 0,
  });
  return (
    <div className='mt-4'>
      <Form className='space-y-8 divide-y divide-gray-200 '>
        <div className='space-y-8 divide-y divide-gray-200'>
          <div>
            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
              <div className='sm:col-span-3'>
                <TextInput
                  name='title'
                  type='text'
                  placeholder='Sales Head'
                  label='Job Role / Title'
                />
              </div>
              <div className='sm:col-span-3'>
                <Select name='placementCompanyId' label='Company'>
                  <option value=''>Select Company</option>
                  {allCompany.map((company: any) => {
                    return (
                      <option value={company._id} key={company._id}>
                        {company.name}{' '}
                      </option>
                    );
                  })}
                </Select>
              </div>
              <div className='sm:col-span-3'>
                <Select name='placementCycleId' label='Placement Cycle'>
                  <option value=''>Select Placement Cycle</option>
                  {allCycle.map((cycle: any) => {
                    return (
                      <option value={cycle._id} key={cycle._id}>
                        {cycle.name}
                      </option>
                    );
                  })}
                </Select>
                {cycleSuccess && allCycle.length < 1 && (
                  <div className='text-sm text-red-600 mt-1'>
                    {' '}
                    <Link to='/cycle/add'>Add Placement Cycle</Link>{' '}
                  </div>
                )}
              </div>
              <div className='sm:col-span-3'>
                <TextInput
                  name='domain'
                  type='text'
                  placeholder='Sales and Marketing'
                  label='Domain'
                />
              </div>
              <div className='sm:col-span-3'>
                <Select name='status' label='Status'>
                  {Object.values(placementRole.status).map((level, index) => {
                    return (
                      <option key={index} value={level}>
                        {level}{' '}
                      </option>
                    );
                  })}
                </Select>
              </div>
              <div className='sm:col-span-6'>
                <div className='col-span-6 sm:col-span-3'>
                  <label
                    // htmlFor='firstName'
                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                  >
                    Skill Required
                  </label>
                  <FieldArray
                    name='skills'
                    render={(arrayHelpers) => (
                      <div className='sm:flex space-x-4'>
                        {values.skills && values.skills.length > 0 ? (
                          values.skills.map((skill: any, index: any) => (
                            <div key={index} className='flex'>
                              <TextInput
                                name={`skills[${index}]`}
                                type='text'
                                placeholder='Skill'
                                label=''
                              />
                              <div className='flex gap-4 ml-4'>
                                <button
                                  type='button'
                                  onClick={() => arrayHelpers.remove(index)} // remove a course from the list
                                >
                                  -
                                </button>
                                <button
                                  type='button'
                                  onClick={() => arrayHelpers.push('')} // insert an empty string at a position
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <button
                            type='button'
                            onClick={() => arrayHelpers.push('')}
                            className='mt-1'
                          >
                            {/* show this when user has removed all courses from the list */}
                            Add a Skill
                          </button>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className='sm:col-span-3'>
                <Select name='experienceLevel' label='Experience Level'>
                  {Object.values(placementRole.experienceLevels).map(
                    (level, index) => {
                      return (
                        <option key={index} value={level}>
                          {level}{' '}
                        </option>
                      );
                    }
                  )}
                </Select>
              </div>
              <div className='sm:col-span-3'>
                <Select
                  name='educationQualification'
                  label='Education Qualification'
                >
                  {Object.values(placementRole.educationQualification).map(
                    (level, index) => {
                      return (
                        <option key={index} value={level}>
                          {level}{' '}
                        </option>
                      );
                    }
                  )}
                </Select>
              </div>
              <div className='sm:col-span-3'>
                <Select name='workCulture' label='Work Culture'>
                  {Object.values(placementRole.workCulture).map(
                    (level, index) => {
                      return (
                        <option key={index} value={level}>
                          {level}{' '}
                        </option>
                      );
                    }
                  )}
                </Select>
              </div>
              <div className='sm:col-span-3'>
                <Select name='roleType' label='Role Type'>
                  {Object.values(placementRole.roleTypes).map(
                    (level, index) => {
                      return (
                        <option key={index} value={level}>
                          {level}{' '}
                        </option>
                      );
                    }
                  )}
                </Select>
              </div>

              <div className='sm:col-span-6'>
                <TextArea label='About' name='roleDescription' />
              </div>
              <div className='sm:col-span-3'>
                <TextInput
                  name='totalOpening'
                  type='number'
                  placeholder='Total Opening'
                  label='Total Opening'
                />
              </div>
              <div className='sm:col-span-2'>
                <TextInput
                  name='payRange'
                  type='number'
                  placeholder='12'
                  label='Salary (In LPA)'
                />
              </div>
              <div className='sm:col-span-1 mt-6'>
                <Checkbox name='disclosedSalary'> Disclose Salary</Checkbox>
              </div>
            </div>
          </div>
        </div>
        <div className='pt-5'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>
            Placement Stages
          </h3>
          <div className='grid grid-cols-1 sm:grid-cols-6 mt-4'>
            <div className='col-span-2'>
              {/* <label
                className='block text-sm font-medium text-neutral-900 md:font-bold'
              >
                Stage
              </label> */}
              <FieldArray
                name='stages'
                render={(arrayHelpers) => (
                  <div className='sm:flex flex-col'>
                    {values.stages && values.stages.length > 0 ? (
                      values.stages.map((stage: any, index: any) => (
                        <div key={index} className='flex space-y-4'>
                          <TextInput
                            name={`stages[${index}]`}
                            type='text'
                            placeholder='Stage'
                            label=''
                          />
                          <div className='flex gap-4 ml-4'>
                            <button
                              type='button'
                              onClick={() => arrayHelpers.remove(index)} // remove a course from the list
                            >
                              -
                            </button>
                            <button
                              type='button'
                              onClick={() => arrayHelpers.push('')} // insert an empty string at a position
                            >
                              +
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <button
                        type='button'
                        onClick={() => arrayHelpers.push('')}
                        className='mt-1'
                      >
                        {/* show this when user has removed all courses from the list */}
                        Add a Stage
                      </button>
                    )}
                  </div>
                )}
              />
            </div>
            <div className='col-span-4 flex flex-col items-center'>
              <div className='space-y-4'>
                {values.stages.map((stage: any, index: any) => {
                  return (
                    stage && (
                      <div>
                        <p className='text-gray-500'>
                          <span className='inline-block mr-2 font-medium text-gray-900'>
                            {index + 1}
                          </span>
                          {stage}
                        </p>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className='pt-5'>
          <div className='flex justify-end gap-3'>
            <button
              disabled={isSubmitting}
              type='submit'
              className={saveButtonStyle}
            >
              {isSubmitting ? 'Updating' : 'Save'}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}
