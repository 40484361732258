import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBasicOverview: builder.query<any, any>({
      query: (slug) => {
        return {
          url: 'dashboard/placement/overview',
          params: slug,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['BasicOverview'],
    }),

    getApplicationOverview: builder.query<any, any>({
      query: (slug) => {
        return {
          url: 'dashboard/placement/application',
          params: slug,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['ApplicationOverview'],
    }),
  }),
});

export const { useGetBasicOverviewQuery, useGetApplicationOverviewQuery } =
  extendedApiSlice;
