import { axiosApi } from 'config/Axios';

type Role = {
  id: string;
  name: string;
};

export function addApplications(role: Role, users: string[]): any {
  return Promise.all(
    users.map((user) => {
      return axiosApi.post('/placement-application', {
        userId: user,
        placementRoleId: role.id,
      });
    })
  );
}
