export const errorMessageStyle = 'text-sm text-red-600 mt-1';
export const labelStyle = 'block text-sm font-medium text-gray-700';
export const buttonStyle =
  'inline-flex items-center justify-center rounded-md border  px-4 py-2 text-sm font-medium text-primary border-primary shadow-sm hover:bg-primary-lighter focus:bg-primary focus:text-white focus:outline-none sm:w-auto';
export const inputStyle =
  'mt-1 block w-full rounded-md border p-2 border-gray-300 shadow-sm sm:text-sm mfocus:ring-primary-light';
export const selectStyle =
  'mt-1 block w-full rounded-md border py-2 px-3 border-gray-300 shadow-sm sm:text-sm focus:ring-primary-light';
export const saveButtonStyle =
  'inline-flex items-center justify-center rounded-md border  px-4 py-2 text-sm font-medium text-white bg-primary border-primary shadow-sm hover:bg-primary-lighter hover:text-primary focus:bg-primary focus:text-white focus:outline-none sm:w-auto';

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.item.values };
    default:
      return state;
  }
};
