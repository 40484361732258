import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { Formik, FormikErrors, useFormikContext } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmployerForm from './EmployerForm';
import { EmployerType } from './types';
import AddHeader from './AddHeader';
import { useAddEmployerMutation } from 'features/employers/EmployerSlice';
import { Link, useNavigate } from 'react-router-dom';

const pages = [
  { name: 'Employers', href: '/employers', current: false },
  { name: 'Add Employer', href: '/add', current: true },
];

const initialValues = {
  name: '',
  website: '',
  industryId: '',
  companyDescription: '',
  companyLogo: '',
  contactPerson: [
    {
      name: '',
      email: '',
      contactNumber: '',
    },
  ],
  socialMediaHandles: {
    linkedIn: '',
    twitter: '',
    instagram: '',
    youtube: '',
    facebook: '',
  },
};

const ShowErrorsInToast = () => {
  // Grab values and submitForm from context
  const {
    errors,
    isSubmitting,
  }: {
    errors: FormikErrors<EmployerType>;
    isSubmitting: boolean;
  } = useFormikContext();

  useEffect(() => {
    if (errors && isSubmitting) {
      //First dismiss all toasts
      toast.dismiss();
      //Show errors in toast
      Object.values(errors).forEach((value: any) => {
        toast.error(value);
      });
    }
  }, [errors, isSubmitting]);

  return null;
};

const AddEmployer = () => {
  const [addEmployer] = useAddEmployerMutation();
  const navigate = useNavigate();

  return (
    <div className='flex flex-col'>
      <nav className='flex mb-4' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to='/' className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
                <Link
                  to={page.href}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          name: Yup.string().required('Name is a required field.'),
          website: Yup.string().url().required('Website is a required field.'),
          industryId: Yup.string().required('Industry is a required field.'),
          companyDescription: Yup.string(),
          contactPerson: Yup.array().of(
            Yup.object().shape({
              name: Yup.string(),
              email: Yup.string().email(),
              contactNumber: Yup.number(),
            })
          ),
          socialMediaHandles: Yup.object().shape({
            linkedIn: Yup.string().url(),
            twitter: Yup.string().url(),
            instagram: Yup.string().url(),
            youtube: Yup.string().url(),
            facebook: Yup.string().url(),
          }),
        })}
        onSubmit={(values, { setSubmitting }) => {
          addEmployer(values)
            .unwrap()
            .then((res) => navigate('/employers'))
            .catch((err) => {
              setSubmitting(false);
              toast.error('Something went wrong. Please try again.');
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <>
            <ShowErrorsInToast />
            <AddHeader />

            <EmployerForm
              values={values}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default AddEmployer;
