import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/24/outline';
import { buttonStyle } from 'global/helper';
import { Link } from 'react-router-dom';

const pages = [{ name: 'Placement Cycle', href: '/cycle', current: true }];

const NoCycle = () => {
  return (
    <div>
      <nav className='flex mb-4' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to='/' className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
                <Link
                  to={page.href}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <div className='text-center'>
        <svg
          className='mx-auto h-12 w-12 text-gray-400'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
          aria-hidden='true'
        >
          <path
            vectorEffect='non-scaling-stroke'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
          />
        </svg>
        <h3 className='mt-2 text-sm font-medium text-gray-900'>
          No Placement Cycle
        </h3>
        <p className='mt-1 text-sm text-gray-500'>
          Get started by creating a new Placement Cycle
        </p>
        <div className='mt-6'>
          <Link to='/cycle/add'>
            <button type='button' className={buttonStyle}>
              <PlusIcon className=' className="-ml-1 mr-2 h-5 w-5"' />
              Add Placement Cycle
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoCycle;
