import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const TablePagination = ({
  totalPages,
  count,
  page,
  setPage,
  limit,
  setLimit,
  isFetching,
}: any) => {
  const handleChangeRowsPerPage = (event: any) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handleChangePage = (newPage: any) => {
    setPage(newPage);
  };

  const countStart = page * limit + 1;
  const countEnd = (page + 1) * limit;

  const leftDisabled = page === 0 || isFetching;
  const rightDisabled = page === totalPages - 1 || isFetching;
  return (
    <div className='flex justify-end my-5 gap-5'>
      <div>
        Rows per page:{' '}
        <select value={limit} onChange={handleChangeRowsPerPage}>
          <option value={10}>10</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>{' '}
      </div>
      <div className='mt-2'>
        {countStart} - {countEnd} of {count}
      </div>
      <button
        onClick={() => handleChangePage(Math.max(0, page - 1))}
        disabled={leftDisabled}
      >
        <ChevronLeftIcon
          className={leftDisabled ? 'w-6 text-gray-400' : 'w-6 text-primary'}
        />
      </button>
      <button
        onClick={() => handleChangePage(Math.min(totalPages - 1, page + 1))}
        disabled={rightDisabled}
      >
        <ChevronRightIcon
          className={rightDisabled ? 'w-6 text-gray-400' : 'w-6 text-primary'}
        />
      </button>
    </div>
  );
};

export default TablePagination;
