import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { EyeIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import SearchBox from 'layout/SearchBox';
// import Options from 'layout/Options';
import { Link } from 'react-router-dom';
import Header from './Header';
// import { EmployerType } from './types';
import Loading from 'layout/Loading';
import Error from 'layout/Error';
import NoEmployer from './NoEmployer';
import { useGetEmployersQuery } from 'features/employers/EmployerSlice';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';

const pages = [{ name: 'Employers', href: '/employers', current: true }];

export default function AllEmployers() {
  const [searchkey, setSearchKey] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const { data, isLoading, isSuccess, isError } = useGetEmployersQuery({
    limit: 0,
    searchKeyword,
  });
  return (
    <div className='flex flex-col'>
      <nav className='flex mb-4' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to='/' className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
                <Link
                  to={page.href}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <Header />
      {isLoading && <Loading />}
      {isError && <Error />}
      {isSuccess &&
        (data.length ? (
          <>
            <div className='flex justify-end'>
              <SearchBox
                setSearchKeyword={setSearchKeyword}
                searchkey={searchkey}
                setSearchKey={setSearchKey}
              />
            </div>
            <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8'>
              <div className='inline-block  min-w-full py-2 align-middle md:px-6 lg:px-8'>
                <div className='shadow ring-1 ring-black ring-opacity-5'>
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                        >
                          Name
                        </th>
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        >
                          Industry
                        </th>
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        >
                          Status
                        </th>
                        <th
                          scope='col'
                          className='relative py-3.5 pl-3 pr-4 sm:pr-6'
                        >
                          <span className='sr-only'>Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {data.map((employer: any) => (
                        <tr key={employer._id}>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='h-10 w-10 flex-shrink-0'>
                                {employer.companyLogo && (
                                  <img
                                    className='h-10 w-10 rounded-full'
                                    src={
                                      employer.companyLogo
                                        ? UPLOAD_OR_DOWNLOAD_URL +
                                          '/' +
                                          employer.companyLogo
                                        : ''
                                    }
                                    alt=''
                                  />
                                )}
                              </div>
                              <div className='ml-4'>
                                <div className='font-medium text-gray-900'>
                                  {employer.name}
                                </div>
                                <div className='text-gray-500'>
                                  {employer.website}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div className='text-gray-900'>
                              {employer.industryId.title}
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <span className='inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800'>
                              Active
                            </span>
                          </td>

                          {/* <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                      <Options
                        options={[
                          {
                            text: 'Edit',
                            link: `/employers/edit/${employer._id}`,
                          },
                          {
                            text: 'View Roles',
                            link: `/roles`,
                          },
                        ]}
                      />
                    </td> */}
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex gap-4'>
                            <Link to={`/employers/edit/${employer._id}`}>
                              <PencilSquareIcon className='w-6' />
                            </Link>
                            <Link to={`/employers/roles/${employer._id}`}>
                              <EyeIcon className='w-6' />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <NoEmployer />
        ))}
    </div>
  );
}
