import { FunnelIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import { useGetPartnerQuery } from 'features/PartnerSlice';
import Multiselect from 'multiselect-react-dropdown';

const Index = ({
  view,
  setView,
  year,
  setYear,
  grade,
  setGrade,
  backlog,
  setBackLog,
  stream,
  setStream,
  setCourse,
  course,
}: {
  view: boolean;
  setView: any;
  year: any;
  setYear: any;
  grade: any;
  setGrade: any;
  backlog: any;
  setBackLog: any;
  stream: any;
  setStream: any;
  course: any;
  setCourse: any;
}) => {
  const { data, isSuccess } = useGetPartnerQuery();

  const graduationYears = [
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2033,
  ];

  const streams = data?.partnerId?.streams || [];
  const courses = data?.partnerId?.courses || [];
  const isTeamMember = data?.roles?.includes('Partner Team');
  const teamMemberStream = data?.stream || '';
  console.log(data?.partnerId);
  function clearFilter() {
    setStream([]);
    setCourse([]);
    setGrade('ALL');
    setGrade();
    setBackLog();
    setYear([]);
    setView(false);
  }

  const style = {
    searchBox: {
      borderRadius: '0.5rem',
      boxShadow:
        '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1',
      borderStyle: 'none',
    },
  };
  return (
    <div className='relative'>
      <FunnelIcon
        onClick={() => {
          setView((prev: any) => !prev);
        }}
        className='w-6 mb-4 text-gray-500 cursor-pointer'
      />

      <div
        className={classNames(
          'absolute z-10 bg-white p-4 rounded shadow',
          {
            hidden: !view,
          },
          { block: view }
        )}
      >
        <div className='flex mb-4 gap-16'>
          <p className=' text-gray-900 font-medium'>Select Filters</p>
          <button
            className='text-gray-500 text-sm hover:text-gray-900 ease-in-out duration-300'
            onClick={() => clearFilter()}
          >
            Clear
          </button>
        </div>

        {isSuccess && (
          <div className='flex gap-4'>
            <div className='w-64'>
              <label className='font-medium text-gray-500 mb-1'>Streams</label>
              <Multiselect
                style={style}
                placeholder='Select'
                selectedValues={isTeamMember ? [teamMemberStream] : stream}
                isObject={false}
                options={streams}
                onSelect={(selectedList: any) => setStream(selectedList)}
                onRemove={(selectedList: any) => setStream(selectedList)}
                disable={isTeamMember}
              />
            </div>

            <div className='w-64'>
              <label className='font-medium text-gray-500 mb-1'>Course</label>
              <Multiselect
                style={style}
                placeholder='Select'
                selectedValues={course}
                isObject={false}
                options={courses}
                onSelect={(selectedList: any) => setCourse(selectedList)}
                onRemove={(selectedList: any) => setCourse(selectedList)}
                // disable={isTeamMember}
              />
            </div>

            <div className='w-64'>
              <label className='font-medium text-gray-500 mb-1'>
                Graduation Year
              </label>

              <Multiselect
                style={style}
                placeholder='Select'
                selectedValues={year}
                isObject={false}
                options={graduationYears}
                onSelect={(selectedList: any) => setYear(selectedList)}
                onRemove={(selectedList: any) => setYear(selectedList)}
              />
            </div>
            <div className='1/2'>
              <div className='relative mt-1'>
                <span className='font-medium text-gray-500 mb-1'>
                  Minimum Grade
                </span>
                <input
                  placeholder='ALL'
                  className='w-32 h-12 rounded-lg shadow-md border-none'
                  value={grade}
                  type='number'
                  onChange={(e) => {
                    setGrade(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className='1/2'>
              <div className='relative mt-1'>
                <span className='font-medium text-gray-500 mb-1'>
                  Maximum Backlogs
                </span>
                <input
                  placeholder='ALL'
                  className='w-40 h-12 rounded-lg shadow-md border-none'
                  value={backlog}
                  type='number'
                  onChange={(e) => {
                    setBackLog(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
