import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPartner: builder.query<any, void>({
      query: () => '/admin/partner',
      transformResponse: (res: any) => res.data,
      providesTags: ['Partner'],
    }),
    updatePartner: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/partner/add-details`,
          method: 'PUT',
          body,
        };
      },
      // Invalidates all queries that subscribe to the `Partner`.
      invalidatesTags: ['Partner'],
    }),
  }),
});

export const { useGetPartnerQuery, useUpdatePartnerMutation } =
  extendedApiSlice;
