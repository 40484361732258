import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import Header from './Header';
// import { StudentBasic } from './types';
import { Link } from 'react-router-dom';
import StudentsTable from './StudentsTable';
import Filter from 'components/filter';
import { useState } from 'react';
import { useGetStudentsQuery } from './PartnerStudents';
import Loading from 'layout/Loading';
import Error from 'layout/Error';
import ViewToggle from './ViewToggle';
import StudentStatsBox from 'components/students/StudentStatsBox';
import SearchBox from 'layout/SearchBox';
import TablePagination from 'layout/TablePagination';

const pages = [{ name: 'Students', href: '/students', current: true }];

export default function AllStudents() {
  const [searchkey, setSearchKey] = useState('');
  const [view, setView] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [year, setYear] = useState([]);
  const [grade, setGrade] = useState();
  const [backlog, setBackLog] = useState();
  const [stream, setStream] = useState([]);
  const [course, setCourse] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [tableView, setTableview] = useState<boolean>(true);

  const { data, isError, isSuccess, isLoading, isFetching } =
    useGetStudentsQuery({
      graduationYear: year.length === 0 ? '' : year,
      stream: stream.length === 0 ? '' : stream,
      course: course.length === 0 ? '' : course,
      gradeGreaterThan: grade,
      maxBacklog: backlog,
      searchKeyword,
      limit,
      page,
      sort: 'firstName',
    });

  return (
    <>
      {isLoading && <Loading />}
      {isError && <Error />}
      {isSuccess && (
        <div className='flex flex-col'>
          <nav className='flex mb-4' aria-label='Breadcrumb'>
            <ol className='flex items-center space-x-4'>
              <li>
                <div>
                  <Link to='/' className='text-gray-400 hover:text-gray-500'>
                    <HomeIcon
                      className='h-5 w-5 flex-shrink-0'
                      aria-hidden='true'
                    />
                    <span className='sr-only'>Home</span>
                  </Link>
                </div>
              </li>
              {pages.map((page) => (
                <li key={page.name}>
                  <div className='flex items-center'>
                    <ChevronRightIcon
                      className='h-5 w-5 flex-shrink-0 text-gray-400'
                      aria-hidden='true'
                    />
                    <Link
                      to={page.href}
                      className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                      aria-current={page.current ? 'page' : undefined}
                    >
                      {page.name}
                    </Link>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
          <Header />
          <div className='flex justify-between'>
            <Filter
              view={view}
              setView={setView}
              year={year}
              setYear={setYear}
              grade={grade}
              setGrade={setGrade}
              setBackLog={setBackLog}
              backlog={backlog}
              setStream={setStream}
              stream={stream}
              setCourse={setCourse}
              course={course}
            />
            <div className='flex gap-4'>
              <SearchBox
                setSearchKeyword={setSearchKeyword}
                searchkey={searchkey}
                setSearchKey={setSearchKey}
              />
              <ViewToggle setTableview={setTableview} tableView={tableView} />
            </div>
          </div>

          {tableView ? (
            <div>
              <StudentsTable data={data?.data || []} />
              <TablePagination
                count={data.totalCount}
                limit={limit}
                totalPages={data.totalPages}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                isFetching={isFetching}
              />
            </div>
          ) : (
            <StudentStatsBox data={data?.data || []} />
          )}
        </div>
      )}
    </>
  );
}
