import { Form } from 'formik';
import Multiselect from 'multiselect-react-dropdown';
import { saveButtonStyle } from 'global/helper';
import { TextArea, TextInput, Checkbox } from 'layout/FormElements';
import { useNavigate } from 'react-router-dom';
import { AnnouncementType } from './types';

const AnnouncementForm = ({
  data,
  values,
  setFieldValue,
  isSubmitting,
}: {
  data: any;
  values: AnnouncementType;
  setFieldValue: any;
  isSubmitting: boolean;
}) => {
  const navigate = useNavigate();
  const streams = data.streams;
  const courses = data.courses;
  const years = [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029];

  return (
    <div>
      <Form className='space-y-8 divide-y divide-gray-200 '>
        <div className='space-y-8 divide-y divide-gray-200'>
          <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
            <div className='sm:col-span-3'>
              <TextInput label='Title' name='title' type='text' />
            </div>
            <div className='sm:col-start-1 sm:col-span-3'>
              <TextArea label='Announcement' name='message' />
            </div>
            <div className='sm:col-start-1 sm:col-span-3'>
              <Multiselect
                placeholder='Select Streams'
                selectedValues={values.streams}
                isObject={false}
                options={streams}
                onSelect={(selectedList: any) =>
                  setFieldValue('streams', selectedList)
                }
                onRemove={(selectedList: any) =>
                  setFieldValue('streams', selectedList)
                }
              />
            </div>
            <div className='sm:col-start-1 sm:col-span-3'>
              <Multiselect
                placeholder='Select Courses'
                selectedValues={values.courses}
                isObject={false}
                options={courses}
                onSelect={(selectedList: any) =>
                  setFieldValue('courses', selectedList)
                }
                onRemove={(selectedList: any) =>
                  setFieldValue('courses', selectedList)
                }
              />
            </div>
            <div className='sm:col-start-1 sm:col-span-3'>
              <Multiselect
                placeholder='Select Graduation Years'
                selectedValues={values.graduationYears}
                isObject={false}
                options={years}
                onSelect={(selectedList: any) =>
                  setFieldValue('graduationYears', selectedList)
                }
                onRemove={(selectedList: any) =>
                  setFieldValue('graduationYears', selectedList)
                }
              />
            </div>

            <div className='sm:col-start-1 sm:col-span-3'>
              <Checkbox name='actionRequired'>
                Is student action required
              </Checkbox>
            </div>

            <div className='pt-5 sm:col-start-1 sm:col-span-3'>
              <div className='flex justify-end gap-3'>
                <button
                  onClick={() => {
                    navigate('/announcements');
                  }}
                  type='button'
                  className='rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                >
                  Cancel
                </button>
                <button type='submit' className={saveButtonStyle}>
                  {isSubmitting ? 'Updating' : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AnnouncementForm;
