import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { useGetCycleQuery } from 'features/cycle/CycleSlice';
import Error from 'layout/Error';
import Loading from 'layout/Loading';
import { Link, useParams } from 'react-router-dom';
// import NoApplications from 'components/applications/NoApplications';
import { useGetRolesQuery } from 'features/roles/RoleSlice';
import AllRoles from 'components/roles/AllRoles';
import Tabs from './Tabs';
import { useState } from 'react';
import Students from './students';
import { PlusIcon } from '@heroicons/react/24/outline';
import { buttonStyle } from 'global/helper';
import SearchBox from 'layout/SearchBox';

const ViewCycle = () => {
  const [view, setView] = useState('Roles');
  let { cycleid } = useParams();

  const [searchkey, setSearchKey] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  const { data, isSuccess, isLoading, isError } = useGetCycleQuery({
    placementCycleId: cycleid,
  });

  const pages = [
    { name: 'Placement Cycles', href: '/cycle', current: false },
    {
      name: data && data[0]?.name,
      href: '/cycle/view/' + cycleid,
      current: true,
    },
  ];
  const appData = useGetRolesQuery({
    placementCycleId: cycleid,
    searchKeyword,
    limit: 0,
  });
  const success = isSuccess && appData.isSuccess;
  const loading = isLoading || appData.isLoading;
  const error = isError || appData.isError;

  return (
    <>
      {loading && <Loading />}
      {error && <Error />}
      {success && (
        <div className='flex flex-col'>
          <nav className='flex mb-4' aria-label='Breadcrumb'>
            <ol className='flex items-center space-x-4'>
              <li>
                <div>
                  <Link to='/' className='text-gray-400 hover:text-gray-500'>
                    <HomeIcon
                      className='h-5 w-5 flex-shrink-0'
                      aria-hidden='true'
                    />
                    <span className='sr-only'>Home</span>
                  </Link>
                </div>
              </li>
              {pages.map((page) => (
                <li key={page.name}>
                  <div className='flex items-center'>
                    <ChevronRightIcon
                      className='h-5 w-5 flex-shrink-0 text-gray-400'
                      aria-hidden='true'
                    />
                    <Link
                      to={page.href}
                      className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                      aria-current={page.current ? 'page' : undefined}
                    >
                      {page.name}
                    </Link>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
          <div className='bg-gray-50 sm:rounded-lg px-4 py-5 sm:p-6'>
            <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium text-gray-500'>
                  Placement Cycle
                </dt>
                <dd className='mt-1 text-sm text-gray-900'>{data[0]?.name}</dd>
              </div>
            </dl>
          </div>

          <div className='mt-4'>
            <div className='flex justify-between'>
              <Tabs view={view} setView={setView} />
              <div className='mt-4 mr-4'>
                <Link to={`/cycle/view/${cycleid}/roles/add`}>
                  <button type='button' className={buttonStyle}>
                    <PlusIcon className=' className="-ml-1 mr-2 h-5 w-5"' />
                    Add Role
                  </button>
                </Link>
              </div>
            </div>
            <div className='mt-4'>
              {view === 'Students' ? (
                <div>
                  <Students cycleData={data[0]} />
                </div>
              ) : (
                <>
                  {appData.data && (
                    <>
                      <div className='flex justify-end'>
                        <SearchBox
                          setSearchKeyword={setSearchKeyword}
                          searchkey={searchkey}
                          setSearchKey={setSearchKey}
                        />
                      </div>
                      <AllRoles data={appData.data} />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewCycle;
