import React from 'react';
import { Squares2X2Icon, TableCellsIcon } from '@heroicons/react/24/outline';

const ViewToggle = ({
  tableView,
  setTableview,
}: {
  tableView: boolean;
  setTableview: (value: boolean) => void;
}) => {
  const iconStyle =
    'w-6 h-6 text-gray-500 cursor-pointer hover:text-gray-900 duration-300 ease-in-out';
  return (
    <div className='flex space-x-4'>
      <TableCellsIcon
        className={iconStyle}
        onClick={() => {
          setTableview(true);
        }}
      />
      <Squares2X2Icon
        className={iconStyle}
        onClick={() => {
          setTableview(false);
        }}
      />
    </div>
  );
};

export default ViewToggle;
