import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { Link, useParams } from 'react-router-dom';
import { Formik, FormikErrors, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { AnnouncementType } from './types';
import * as Yup from 'yup';
import AnnouncementForm from './AnnouncementForm';
import { useGetPartnerQuery } from 'features/PartnerSlice';
import Error from 'layout/Error';
import Loading from 'layout/Loading';
import {
  useGetAnnouncementQuery,
  useUpdateAnnouncementMutation,
} from 'features/announcements/announcementSlice';

const ShowErrorsInToast = () => {
  // Grab values and submitForm from context
  const {
    errors,
    isSubmitting,
  }: {
    errors: FormikErrors<AnnouncementType>;
    isSubmitting: boolean;
  } = useFormikContext();

  useEffect(() => {
    if (errors && isSubmitting) {
      //First dismiss all toasts
      toast.dismiss();
      //Show errors in toast
      Object.values(errors).forEach((value: any) => {
        toast.error(value);
      });
    }
  }, [errors, isSubmitting]);

  return null;
};

const EditAnnouncement = () => {
  const { slug } = useParams();

  const { data, isSuccess, isLoading, isError } = useGetPartnerQuery();
  const [updateAnnouncement] = useUpdateAnnouncementMutation();
  const announcement = useGetAnnouncementQuery({ announcementId: slug });

  const pages = [
    { name: 'Announcements', href: '/announcements', current: false },
    { name: 'Edit', href: '/announcements/edit' + slug, current: true },
  ];

  const initialValues = useMemo(() => {
    const data = announcement.data && announcement.data[0];
    return {
      announcementId: data?._id,
      title: data?.title,
      message: data?.message,
      courses: data?.courses,
      streams: data?.streams,
      graduationYears: data?.graduationYears,
      actionRequired: data?.actionRequired || false,
    };
  }, [announcement.data]);

  return (
    <>
      {isError && <Error />}
      {isLoading && <Loading />}
      {isSuccess && (
        <div>
          <nav className='flex mb-4' aria-label='Breadcrumb'>
            <ol className='flex items-center space-x-4'>
              <li>
                <div>
                  <Link to='/' className='text-gray-400 hover:text-gray-500'>
                    <HomeIcon
                      className='h-5 w-5 flex-shrink-0'
                      aria-hidden='true'
                    />
                    <span className='sr-only'>Home</span>
                  </Link>
                </div>
              </li>
              {pages.map((page) => (
                <li key={page.name}>
                  <div className='flex items-center'>
                    <ChevronRightIcon
                      className='h-5 w-5 flex-shrink-0 text-gray-400'
                      aria-hidden='true'
                    />
                    <Link
                      to={page.href}
                      className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                      aria-current={page.current ? 'page' : undefined}
                    >
                      {page.name}
                    </Link>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
          <div className='sm:flex sm:items-center mb-8'>
            <div className='sm:flex-auto'>
              <h1 className='text-xl font-semibold text-gray-900'>
                Edit Announcement
              </h1>
              <p className='mt-2 text-sm text-gray-700'>
                Edit an existing annoucement
              </p>
            </div>
          </div>
          <ToastContainer />
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object({
              title: Yup.string().required('Title is a required field.'),
              message: Yup.string().required('Message is a required field.'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              updateAnnouncement(values)
                .unwrap()
                .then(() => {
                  toast.success('Announcement edited successfully');
                  resetForm();
                })
                .catch(() => {
                  toast.error('Announcement could not be edited');
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <>
                <ShowErrorsInToast />
                <AnnouncementForm
                  data={data.partnerId}
                  values={values}
                  setFieldValue={setFieldValue}
                  isSubmitting={isSubmitting}
                />
              </>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default EditAnnouncement;
