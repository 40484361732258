import { useGetAnnouncementQuery } from 'features/announcements/announcementSlice';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loading from 'layout/Loading';
import Error from 'layout/Error';
import { format } from 'date-fns';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

const ViewAnoouncement = () => {
  const { slug } = useParams();

  const announcement = useGetAnnouncementQuery({ announcementId: slug });
  const pages = useMemo(() => {
    const initial = [
      { name: 'Announcements', href: '/announcements', current: false },
    ];
    if (announcement.isSuccess) {
      initial.push({
        name: announcement?.data[0]?.title,
        href: '/announcements/view/' + slug,
        current: true,
      });
    }
    return initial;
  }, [slug, announcement.isSuccess, announcement.data]);

  return (
    <div>
      <nav className='flex mb-4' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to='/' className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
                <Link
                  to={page.href}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      {announcement.isLoading && <Loading />}
      {announcement.isError && <Error />}
      {announcement.isSuccess && (
        <div className='mt-8 space-y-4'>
          <p>
            {format(new Date(announcement.data[0].createdAt), 'do MMMM yyy')}
          </p>
          <h1 className='text-xl font-semibold text-gray-900'>
            {announcement.data[0].title}
            <Link
              to={`/announcements/edit/${announcement.data[0]._id}`}
              className='text-indigo-600 hover:text-indigo-900 ml-4 text-sm'
            >
              <PencilSquareIcon className='w-4 inline' />
            </Link>
          </h1>
          <p className='text-gray-500'>{announcement.data[0].message}</p>
          <p className='text-gray-500'>
            Student Action Required:{' '}
            {announcement.data[0].actionRequired ? 'YES' : 'NO'}
          </p>
          <div className='mt-16 bg-blue-50 rounded p-2 inline-block'>
            <p className='font-semibold mb-4 text-sm'>
              Audience Criteria for this announcement
            </p>
            <p>{announcement.data[0].courses.join(',')}</p>
            <p>{announcement.data[0].streams.join(',')}</p>
            <p>{announcement.data[0].graduationYears.join(',')}</p>
          </div>
          {announcement.data[0].actionRequired && (
            <>
              <div>
                {announcement.data[0]?.studentActions?.length} Students took
                action
              </div>
              {announcement.data[0]?.studentActions?.map(
                (user: any, index: number) => {
                  return (
                    <div className='bg-blue-50 p-4 rounded my-2' key={index}>
                      <p>
                        Name: {user.userId?.firstName} {user.userId?.lastName}{' '}
                      </p>
                      <p>Email: {user.userId?.email} </p>
                      <p>Action: {user.action} </p>
                    </div>
                  );
                }
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewAnoouncement;
