import { Form } from 'formik';
import { saveButtonStyle } from 'global/helper';
import { TextInput, DatePickerField } from 'layout/FormElements';
import { useGetPartnerQuery } from 'features/PartnerSlice';
import Multiselect from 'multiselect-react-dropdown';

export default function CycleForm({
  values,
  setFieldValue,
  isSubmitting,
}: any) {
  const { data } = useGetPartnerQuery();

  const streams = data?.partnerId?.streams || [];
  const graduationYears = [
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2033,
  ];

  return (
    <div className='mt-4'>
      <div>
        <h3 className='text-lg font-medium leading-6 text-gray-900'>
          Add a new Placement Cycle
        </h3>
      </div>
      <Form className='space-y-8 divide-y divide-gray-200 '>
        <div className='space-y-8 divide-y divide-gray-200'>
          <div>
            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3'>
              <div className='sm:col-span-2'>
                <TextInput
                  name='name'
                  type='text'
                  placeholder=''
                  label='Placement Cycle Name'
                />
              </div>
              <div className='sm:col-span-3 grid grid-cols-3 gap-4'>
                <div className='col-span-3  sm:col-span-1 '>
                  <DatePickerField
                    label='Start Date'
                    name='startDate'
                    value={values.startDate}
                    onChange={setFieldValue}
                  />
                </div>
                <div className='col-span-3 sm:col-span-1'>
                  <DatePickerField
                    label='End Date'
                    name='endDate'
                    value={values.endDate}
                    onChange={setFieldValue}
                    minDate={
                      values.startDate ? new Date(values.startDate) : null
                    }
                  />
                </div>
              </div>
            </div>
            <div className='my-3 font-semibold'>Eligibility Criteria</div>
            <div className='grid grid-cols-3 gap-4'>
              <div className='col-span-3 sm:col-span-1 mt-2'>
                <Multiselect
                  placeholder='Select Streams'
                  selectedValues={values.streams}
                  isObject={false}
                  options={streams}
                  onSelect={(selectedList: any) =>
                    setFieldValue('streams', selectedList)
                  }
                  onRemove={(selectedList: any) =>
                    setFieldValue('streams', selectedList)
                  }
                />
              </div>
              <div className='col-span-3 sm:col-span-1 mt-2'>
                <Multiselect
                  placeholder='Select Graduation Year'
                  selectedValues={values.graduationYear}
                  isObject={false}
                  options={graduationYears}
                  onSelect={(selectedList: any) =>
                    setFieldValue('graduationYear', selectedList)
                  }
                  onRemove={(selectedList: any) =>
                    setFieldValue('graduationYear', selectedList)
                  }
                />
              </div>
              <div className='col-span-3 sm:col-span-1'>
                <TextInput
                  name='eligibilityCriteria.minGrade'
                  type='number'
                  placeholder='5.5'
                  label='Minimum Grade Required'
                />
              </div>
              <div className='col-span-3 sm:col-span-1'>
                <TextInput
                  name='eligibilityCriteria.maxBacklog'
                  type='number'
                  placeholder='3'
                  label='Maximum Backlogs allowed'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='pt-5'>
          <div className='flex justify-end gap-3'>
            <button
              disabled={isSubmitting}
              type='submit'
              className={saveButtonStyle}
            >
              {isSubmitting ? 'Updating' : 'Save'}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}
