import React from 'react';
import { format } from 'date-fns';

const Projects = ({ data }: { data: any }) => {
  const projectPortfolio = data?.projectPortfolio || [];

  return (
    <div className='px-6 lg:pr-16 py-6  border-b-2 lg:border-r border-primary min-h-64 max-h-fit'>
      <div className='flex space-x-2 items-center'>
        <div className='border-r-2 pr-4 font-semibold text-xl'>
          Portfolios & Projects
        </div>
        <p className='text-sm'>
          {projectPortfolio.length > 0 && projectPortfolio.length}{' '}
        </p>
      </div>

      {projectPortfolio.map((project: any, index: any) => {
        return (
          <div
            key={index}
            className='flex mx-2 sm:mx-0 md:mr-auto space-x-3 items-center px-4 py-2 my-6 border border-textColor-landing rounded-xl'
          >
            <svg
              width='51'
              height='52'
              viewBox='0 0 51 52'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0.0958252 51.0254V45.3879L4.77187 40.7816V51.0254H0.0958252ZM11.6114 51.0254V34.3879L16.3573 29.7816V51.0254H11.6114ZM23.1271 51.0254V29.7816L27.8729 34.4566V51.0254H23.1271ZM34.7125 51.0254V34.3879L39.3885 29.7816V51.0254H34.7125ZM46.2281 51.0254V23.3191L50.9042 18.7129V51.0254H46.2281ZM0.0958252 35.1441V28.4754L19.9167 9.08789L31.0833 20.0879L50.9042 0.494141V7.09414L31.0833 26.7566L19.9167 15.7566L0.0958252 35.1441Z'
                fill='#10ABB7'
              />
            </svg>
            <div>
              <p className='font-semibold  mb-2'>{project.projectName} </p>
              <p className='text-sm mb-1'>{project.client}</p>
              <p className='text-xs  mb-2'>
                {project.startDate &&
                  format(new Date(project.startDate), 'MMM u')}
                -{project.endDate && format(new Date(project.endDate), 'MMM u')}
              </p>
              <button className='px-2 text-sm py-1 border rounded-md'>
                {project.description}
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Projects;
