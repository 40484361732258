import { useGetAnnouncementsQuery } from 'features/announcements/announcementSlice';
import Loading from 'layout/Loading';
import { format } from 'date-fns';
import NoAnnouncements from './NoAnnouncements';
import { Link } from 'react-router-dom';

const RecentAnnouncements = () => {
  const { isLoading, isSuccess, data } = useGetAnnouncementsQuery({
    limit: 5,
  });

  const announcements = data?.map((announcement: any) => {
    return (
      <div className='gap-4 items-center'>
        <Link to={`/announcements/view/${announcement._id}`}>
          <p className='text-primary font-semibold'>{announcement.title}</p>
        </Link>
        <p className='text-sm text-gray-500'>
          {format(new Date(announcement.createdAt), 'do MMMM y')}
        </p>
      </div>
    );
  });
  const content =
    data?.length > 0 ? (
      <div className='space-y-4'>{announcements}</div>
    ) : (
      <NoAnnouncements />
    );
  return (
    <div className='mt-8'>
      {isLoading && <Loading />}
      {isSuccess && (
        <div>
          <div className='sm:flex sm:items-center mb-8'>
            <div className='sm:flex-auto'>
              <h1 className='text-xl font-semibold text-gray-900'>
                Recent Announcements
              </h1>
              <p className='mt-2 text-sm text-gray-700'>
                A list of recent announcements added by you
              </p>
            </div>
          </div>
          {content}
        </div>
      )}
    </div>
  );
};

export default RecentAnnouncements;
