import { useGetStudentsQuery } from 'features/students/PartnerStudents';
import StudentsTable from 'features/students/StudentsTable';
import { useState } from 'react';
import Filter from 'components/filter';
import Loading from 'layout/Loading';
import Error from 'layout/Error';
import SearchBox from 'layout/SearchBox';
import TablePagination from 'layout/TablePagination';

const Index = ({ cycleData }: { cycleData: any }) => {
  const eligibility = cycleData?.eligibilityCriteria;
  const [searchkey, setSearchKey] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [stream, setStream] = useState(cycleData?.streams || []);
  const [course, setCourse] = useState([]);
  const [year, setYear] = useState(cycleData?.graduationYear || []);
  const [grade, setGrade] = useState(eligibility?.minGrade);
  const [backlog, setBackLog] = useState(eligibility?.maxBacklog);
  const [view, setView] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { data, isError, isSuccess, isLoading, isFetching } =
    useGetStudentsQuery({
      graduationYear: year.length === 0 ? '' : year,
      stream: stream.length === 0 ? '' : stream,
      course: course.length === 0 ? '' : course,
      gradeGreaterThan: grade,
      maxBacklog: backlog,
      searchKeyword,
      limit,
      page,
      sort: 'firstName',
    });
  return (
    <>
      {isError && <Error />}
      {isLoading && <Loading />}
      {isSuccess && (
        <div>
          <div className='flex gap-8 text-sm font-medium text-gray-500 mb-4'>
            <p className=''>Eligible Students</p>
            <div>
              <p>
                Minimum Grade: <span>{grade}</span>
              </p>
              <p>
                Maximum Backlogs: <span>{backlog}</span>
              </p>
            </div>
            <div>
              <p>
                Graduation Year:{' '}
                <span>
                  {year.length === 0 ? 'ALL' : year.map((el: any) => el)}
                </span>
              </p>
            </div>
          </div>
          <div className='flex justify-between'>
            <Filter
              view={view}
              setView={setView}
              year={year}
              setYear={setYear}
              grade={grade}
              setGrade={setGrade}
              setBackLog={setBackLog}
              backlog={backlog}
              setStream={setStream}
              stream={stream}
              setCourse={setCourse}
              course={course}
            />
            <SearchBox
              setSearchKeyword={setSearchKeyword}
              searchkey={searchkey}
              setSearchKey={setSearchKey}
            />
          </div>
          <StudentsTable data={data?.data || []} />
          <TablePagination
            count={data.totalCount}
            limit={limit}
            totalPages={data.totalPages}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            isFetching={isFetching}
          />
        </div>
      )}
    </>
  );
};

export default Index;
