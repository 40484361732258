import { useState } from 'react';
import AllRoles from 'components/roles/AllRoles';
import { useGetRolesQuery } from 'features/roles/RoleSlice';
import Loading from 'layout/Loading';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { Link, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useGetEmployersQuery } from 'features/employers/EmployerSlice';
import NoRoles from 'components/roles/NoRoles';
import { buttonStyle } from 'global/helper';
import { PlusIcon } from '@heroicons/react/24/outline';
import SearchBox from 'layout/SearchBox';

const EmployerRoles = () => {
  const { slug } = useParams();
  const [searchkey, setSearchKey] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const {
    data = [],
    isLoading,
    isSuccess,
  } = useGetRolesQuery({
    limit: 0,
    placementCompanyId: slug,
    searchKeyword,
  });

  const emp = useGetEmployersQuery({
    limit: 1,
    placementCompanyId: slug,
  });

  const pages = useMemo(() => {
    const initial = [{ name: 'Employers', href: '/employers', current: false }];
    if (emp.isSuccess) {
      initial.push({
        name: emp?.data[0]?.name,
        href: '/employers/roles/' + slug,
        current: true,
      });
    }
    return initial;
  }, [slug, emp.isSuccess, emp.data]);

  return (
    <div>
      <nav className='flex mb-4' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to='/' className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.href}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
                <Link
                  to={page.href}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>

      {isLoading && <Loading />}
      {isSuccess && (
        <>
          {data.length ? (
            <>
              <div className='my-6 text-right'>
                <Link to='/roles/add'>
                  <button type='button' className={buttonStyle}>
                    <PlusIcon className=' className="-ml-1 mr-2 h-5 w-5"' />
                    Add Role
                  </button>
                </Link>
              </div>
              <div className='flex justify-end'>
                <SearchBox
                  setSearchKeyword={setSearchKeyword}
                  searchkey={searchkey}
                  setSearchKey={setSearchKey}
                />
              </div>
              <AllRoles data={data} />
            </>
          ) : (
            <NoRoles />
          )}
        </>
      )}
    </div>
  );
};

export default EmployerRoles;
