import StudentActions from './StudentActions';
import classNames from 'classnames';
import { MutableRefObject, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';

const StudentsTable = ({ data }: { data: any }) => {
  const checkbox = useRef() as MutableRefObject<HTMLInputElement>;
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState<any>([]);
  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPeople.length > 0 && selectedPeople.length < data.length;
    setChecked(selectedPeople.length === data.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedPeople, data.length]);

  function toggleAll() {
    setSelectedPeople(checked || indeterminate ? [] : data);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }
  return (
    <>
      {selectedPeople.length ? (
        <div className='mb-4'>
          <StudentActions data={data} selectedPeople={selectedPeople} />
        </div>
      ) : (
        ''
      )}
      <div className='flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              {selectedPeople.length > 0 && (
                <div className='hidden absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16'>
                  <button
                    type='button'
                    className='inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'
                  >
                    Bulk edit
                  </button>
                  <button
                    type='button'
                    className='inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'
                  >
                    Delete all
                  </button>
                </div>
              )}
              <table className='min-w-full table-fixed divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='relative w-12 px-6 sm:w-16 sm:px-8'
                    >
                      <input
                        type='checkbox'
                        className='hidden absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6'
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope='col'
                      className='min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'
                    >
                      Name
                    </th>

                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Course
                    </th>

                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Graduation Year
                    </th>

                    <th
                      scope='col'
                      className='relative py-3.5 pl-3 pr-4 sm:pr-6'
                    >
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {data.map((student: any) => (
                    <tr
                      key={student._id}
                      className={
                        selectedPeople.includes(student._id)
                          ? 'bg-gray-50'
                          : undefined
                      }
                    >
                      <td className='relative w-12 px-6 sm:w-16 sm:px-8'>
                        {selectedPeople.includes(student._id) && (
                          <div className='absolute inset-y-0 left-0 w-0.5 bg-indigo-600' />
                        )}
                        <input
                          type='checkbox'
                          className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6'
                          value={student.email}
                          checked={selectedPeople.includes(student._id)}
                          onChange={(e) =>
                            setSelectedPeople(
                              e.target.checked
                                ? [...selectedPeople, student._id]
                                : selectedPeople.filter(
                                    (p: any) => p !== student._id
                                  )
                            )
                          }
                        />
                      </td>
                      <td
                        className={classNames(
                          'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                          selectedPeople.includes(student._id)
                            ? 'text-indigo-600'
                            : 'text-gray-900'
                        )}
                      >
                        <div className='flex'>
                          {student.firstName} {student.lastName}
                          {student.verified && (
                            <CheckBadgeIcon
                              className='h-5 w-5 flex-shrink-0 text-primary ml-2'
                              aria-hidden='true'
                            />
                          )}
                        </div>
                        <p className='text-xs text-gray-500'>{student.email}</p>
                      </td>

                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        <div className='text-gray-900'>{student.course}</div>
                        <div className='text-gray-500'>{student.stream}</div>
                      </td>

                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {student.graduationYear}
                      </td>

                      <td className='whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                        <Link
                          to={`/students/view/${student._id}`}
                          className='text-indigo-600 hover:text-indigo-900'
                        >
                          View
                          <span className='sr-only'>, {student.firstName}</span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentsTable;
