import Routes from 'routes';

function App() {
  return (
    <div className='font-manrope'>
      <Routes />
    </div>
  );
}

export default App;
