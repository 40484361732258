import { useGetCycleQuery } from 'features/cycle/CycleSlice';
import { useGetApplicationOverviewQuery } from 'features/home/homeSlice';
import React, { useState } from 'react';
import ChartData from './ChartData';

const Applications = () => {
  const [cycleId, setCycleId] = useState('ALL');
  const { data: cycleData } = useGetCycleQuery({
    limit: 0,
  });

  const { data, isSuccess } = useGetApplicationOverviewQuery({
    placementCycleId: cycleId === 'ALL' ? '' : cycleId,
  });
  return (
    <>
      {isSuccess && (
        <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 3xl:grid-cols-3'>
          <div className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
            <div className='flex justify-between'>
              <div>
                <dt className='truncate text-sm font-medium text-gray-500'>
                  Applications
                </dt>
                <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
                  {data?.totalApplications}
                </dd>
              </div>
              {cycleData && cycleData.length > 1 && (
                <div className='mx-4'>
                  <select
                    value={cycleId}
                    onChange={(e) => setCycleId(e.target.value)}
                  >
                    <option value='ALL'>ALL</option>
                    {cycleData.map((cycle: any) => {
                      return (
                        <option key={cycle._id} value={cycle._id}>
                          {cycle.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
            <div className='mt-4 grid grid-cols-2'>
              <div className='col-span-1'>
                <p>Applied</p>
                <p>{data?.appliedApplications}</p>
              </div>
              <div className='col-span-1'>
                <p>Shortlisted</p>
                <p>{data?.shortlistedApplications}</p>
              </div>
            </div>
            <div className='mt-4 grid grid-cols-2'>
              <div className='col-span-1'>
                <p>Hired</p>
                <p>{data?.hiredApplications}</p>
              </div>
              <div className='col-span-1'>
                <p>Not Hired</p>
                <p>{data?.notHiredApplications}</p>
              </div>
            </div>
          </div>
          <div>{data?.totalApplications > 0 && <ChartData data={data} />}</div>
        </dl>
      )}
    </>
  );
};

export default Applications;
