import React from 'react';
import { useGetTeamMembersQuery } from './TeamSlice';
import AllTeamMembers from 'components/team/AllTeamMembers';
import Loading from 'layout/Loading';
import Error from 'layout/Error';

const Index = () => {
  const { data, isLoading, isSuccess, isError } = useGetTeamMembersQuery({});
  return (
    <>
      {isLoading && <Loading />}
      {isError && <Error />}
      {isSuccess && <AllTeamMembers data={data} />}
    </>
  );
};

export default Index;
