import RecentAnnouncements from 'components/announcements/RecentAnnouncements';
import Data from 'components/home/Data';

const Index = () => {
  return (
    <div>
      <div className='mt-6'>
        <Data />
        <RecentAnnouncements />
      </div>
    </div>
  );
};

export default Index;
