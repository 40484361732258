import React, { useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { useField, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { axiosApi } from 'config/Axios';

const TextInput = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div>
        <label htmlFor={label} className='sr-only'>
          {label}
        </label>
        <input
          {...field}
          {...props}
          className='relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primary-lighter focus:outline-none focus:ring-primary-lighter sm:text-sm'
        />
        {meta.touched && meta.error ? (
          <div className='text-sm text-red-500'>{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

const ConfirmationForm = ({ enteredEmail, setConfirmed, userId }: any) => {
  const [feedbackMessage, setFeedbackMessage] = useState('');
  return (
    <div>
      <div className='flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='w-full max-w-md space-y-8'>
          <div>
            <img
              className='mx-auto h-12 w-auto'
              src='https://res.cloudinary.com/belong/image/upload/v1650621217/uploaded_resources/logo_gqty7l.png'
              alt='Your Company'
            />
            <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
              Email Confirmation!
            </h2>
            <div>
              If an account exists with{' '}
              <span className='font-semibold'>({enteredEmail})</span>,an email
              has been sent with the confirmation code.
            </div>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              code: '',
              password: '',
            }}
            validationSchema={Yup.object({
              code: Yup.string().required('Code is required'),
              password: Yup.string().required('Password is required'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                await Auth.forgotPasswordSubmit(
                  enteredEmail,
                  values.code,
                  values.password
                );
                if (userId) {
                  await axiosApi.put('/admin/updateProfileStage', {
                    userId,
                    passwordReset: 'success',
                  });
                }
                setConfirmed(true);
              } catch (error: any) {
                setFeedbackMessage(error.message);
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form className='mt-8 space-y-6'>
                <div className='-space-y-px rounded-md shadow-sm'>
                  <TextInput
                    label='Code'
                    name='code'
                    type='text'
                    placeholder='Code'
                    autocomplete='off'
                  />
                </div>
                <div>
                  <TextInput
                    label='Password'
                    name='password'
                    type='password'
                    placeholder='Password'
                    autocomplete='off'
                  />
                </div>

                <div>
                  <button
                    disabled={isSubmitting}
                    type='submit'
                    className='group relative flex w-full justify-center rounded-md border border-transparent bg-primary-light py-2 px-4 text-sm font-medium text-white hover:bg-primary focus:outline-none'
                  >
                    <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                      <LockClosedIcon
                        className='h-5 w-5 text-primary group-hover:text-white'
                        aria-hidden='true'
                      />
                    </span>
                    Reset Password
                  </button>
                </div>

                <div className='text-sm flex justify-end'>
                  <button
                    type='button'
                    // href='#'
                    className='font-medium text-primary hover:text-primary-light'
                  >
                    Forgot your password?
                  </button>
                </div>
                <div>{feedbackMessage} </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationForm;
