import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput, Select } from 'layout/FormElements';
import { useEditTeamMembersMutation } from 'features/team/TeamSlice';
import { useGetPartnerQuery } from 'features/PartnerSlice';

export default function EditMember({
  isOpen,
  closeAddModal,
  adminData,
}: {
  isOpen: boolean;
  closeAddModal: any;
  adminData: any;
}) {
  const [EditTeam] = useEditTeamMembersMutation();
  const partnerRole = ['Partner Team', 'Partner Admin'];
  const otherRole = adminData.roles.filter((role: any) => {
    return !partnerRole.includes(role);
  });
  const isAdmin = adminData.roles.includes('Partner Admin');

  const { data } = useGetPartnerQuery();

  const streams = data?.partnerId?.streams || [];
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeAddModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    Edit Admin
                  </Dialog.Title>
                  <div className='mt-2'>
                    <Formik
                      initialValues={{
                        adminId: adminData._id,
                        name: adminData.name || adminData.firstName,
                        roles: isAdmin ? 'Partner Admin' : 'Partner Team',
                        phone: adminData.contactNumber || '',
                        stream: adminData.stream || '',
                      }}
                      validationSchema={Yup.object({
                        name: Yup.string().required(
                          'Name is a required field.'
                        ),
                      })}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        otherRole.push(values.roles);
                        const myData = { ...values, roles: otherRole };
                        if (values.roles === 'Partner Admin') {
                          myData.stream = '';
                        }
                        EditTeam(myData)
                          .unwrap()
                          .then((res: any) => closeAddModal());
                      }}
                    >
                      {({ values, setFieldValue, isSubmitting }) => (
                        <>
                          <Form className='space-y-8 divide-y divide-gray-200 '>
                            <div className='space-y-8 divide-y divide-gray-200'>
                              <div>
                                <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                                  <div className='sm:col-span-6'>
                                    <TextInput
                                      name='name'
                                      type='text'
                                      placeholder=''
                                      label='Name'
                                    />
                                  </div>

                                  <div className='sm:col-span-6'>
                                    <Select name='roles' label='Role'>
                                      <option value='Partner Team'>Team</option>
                                      <option value='Partner Admin'>
                                        Admin
                                      </option>
                                    </Select>
                                  </div>
                                  <div className='sm:col-span-6'>
                                    <TextInput
                                      name='phone'
                                      type='text'
                                      placeholder=''
                                      label='Phone No.'
                                    />
                                  </div>
                                  {values.roles === 'Partner Team' &&
                                    streams.length > 0 && (
                                      <div className='sm:col-span-6'>
                                        <Select name='stream' label='Stream'>
                                          {streams.map((el: any) => {
                                            return (
                                              <option key={el} value={el}>
                                                {' '}
                                                {el}{' '}
                                              </option>
                                            );
                                          })}
                                        </Select>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>

                            <div className='pt-5'>
                              <div className='flex justify-end gap-3'>
                                <button
                                  disabled={isSubmitting}
                                  type='submit'
                                  // className={saveButtonStyle}
                                >
                                  {isSubmitting ? 'Updating' : 'Save'}
                                </button>
                              </div>
                            </div>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
