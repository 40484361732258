// import React, { useState } from 'react';

import StudentBox from './StudentBox';

// const studentInfo = [
//   {
//     id: 1,
//     name: 'Elurai Tarun',
//     pic: '/images/students/one.png',
//     title: 'Software Developer',
//     degree: 'B.tech CS',
//     cgpa: '8.00 CGPA',
//     internships: '2 Internships',
//   },
//   {
//     id: 2,
//     name: 'Wade Warren',
//     pic: '/images/students/two.png',
//     title: 'Software Developer',
//     degree: 'B.tech CS',
//     cgpa: '8.00 CGPA',
//     internships: '2 Internships',
//   },
//   {
//     id: 3,
//     name: 'Jane Cooper',
//     pic: '/images/students/three.png',
//     title: 'Software Developer',
//     degree: 'B.tech CS',
//     cgpa: '8.00 CGPA',
//     internships: '2 Internships',
//   },
// ];

const StudentStatsBox = ({ data }: { data: any }) => {
  // const [isActive, SetIsActive] = useState(false);
  // return data?.map((student: any) => {
  //   return <StudentBox key={student._id} data={student} />;
  // });
  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8'>
        {data?.map((student: any) => {
          return <StudentBox key={student._id} data={student} />;
        })}
      </div>
      {/* <div className='p-4'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8'>
          {studentInfo.map((info) => {
            return (
              <div
                key={info.id}
                className={classNames(
                  'px-4 pt-6 pb-4 space-y-6 cursor-pointer text-textColor-landing border-2 border-customGrey rounded-md',
                  {
                    'border-primary': isActive,
                  }
                )}
              >
                <div className='relative flex space-x-4 items-center'>
                  <img
                    src={info.pic}
                    alt='studentPic'
                    className={classNames(
                      'w-[72px] h-[72px] rounded-full grayscale',
                      {
                        'grayscale-0': isActive,
                      }
                    )}
                  />
                  <div className='space-y-2'>
                    <p className='font-semibold text-lg'>{info.name}</p>
                    <p>{info.title}</p>
                  </div>
                  <input
                    onClick={() => SetIsActive(true)}
                    type='checkbox'
                    className='absolute right-0 -top-2 w-5 h-5'
                  />
                </div>
                <div className='space-y-2'>
                  <div className='flex flex-row'>
                    <div className='flex  basis-1/2 space-x-2 items-center'>
                      <img
                        src='/images/icons/degreeIcon.png'
                        alt='degreeicon'
                        className='h-4 w-4 object-contain'
                      />
                      <p>{info.degree}</p>
                    </div>
                    <div className='basis-1/2 border h-6 rounded-2xl text-sm'>
                      <p
                        className={classNames(
                          'w-1/2 h-6 pl-4 flex items-center bg-grey-custom rounded-l-2xl text-neutral-900 text-xs',
                          { 'bg-primary-lightest': isActive }
                        )}
                      >
                        Power BI
                      </p>
                    </div>
                  </div>
                  <div className='flex flex-row'>
                    <div className='flex basis-1/2 space-x-2 items-center'>
                      <img
                        src='/images/icons/cgpaIcon.png'
                        alt='cgpaicon'
                        className='h-4 w-4 object-contain'
                      />
                      <p>{info.cgpa}</p>
                    </div>
                    <div className='basis-1/2 border h-6 rounded-2xl text-sm'>
                      <p
                        className={classNames(
                          'w-[90%] h-6 pl-4 flex items-center bg-grey-custom rounded-l-2xl text-neutral-900 text-xs',
                          { 'bg-primary-lightest': isActive }
                        )}
                      >
                        Python
                      </p>
                    </div>
                  </div>
                  <div className='flex flex-row'>
                    <div className='flex  basis-1/2 space-x-2 items-center'>
                      <img
                        src='/images/icons/internshipIcon.png'
                        alt='internshipicon'
                        className='h-4 w-4 object-contain'
                      />
                      <p>{info.internships}</p>
                    </div>
                    <div className='basis-1/2 border h-6 rounded-2xl text-sm'>
                      <p
                        className={classNames(
                          'w-3/4 h-6 pl-4 flex items-center bg-grey-custom rounded-l-2xl text-neutral-900 text-xs',
                          { 'bg-primary-lightest': isActive }
                        )}
                      >
                        Statistics
                      </p>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between'>
                  <p className='cursor-pointer underline  decoration-textColor-landing'>
                    View Details
                  </p>
                  <p
                    onClick={() => SetIsActive(false)}
                    className='text-primary font-semibold'
                  >
                    SHARE
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
    </>
  );
};

export default StudentStatsBox;
