import { useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { useField, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { axiosApi } from 'config/Axios';
import ResetForm from './resetPassword/ResetForm';
import ConfirmationForm from './resetPassword/ConfirmationForm';
import { setToken } from 'routes/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { buttonStyle } from 'global/helper';

const TextInput = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div>
        <label htmlFor={label} className='sr-only'>
          {label}
        </label>
        <input
          {...field}
          {...props}
          className='relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primary-lighter focus:outline-none focus:ring-primary-lighter sm:text-sm'
        />
        {meta.touched && meta.error ? (
          <div className='text-sm text-red-500'>{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [feedbackMessage, setFeedbackMessage] = useState('');

  const [hideLogin, setHideLogin] = useState(true);
  const [nextLoading, setNextLoading] = useState(false);

  const [resetPage, setResetPage] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const [userId, setUserId] = useState(null);

  async function checkEmail(email: any) {
    try {
      setNextLoading(true);
      setEnteredEmail(email);
      const result = await axiosApi.get('/admin/checkProfileStage', {
        params: {
          email: email,
        },
      });
      setNextLoading(false);
      if (!result.data.data) {
        setFeedbackMessage('User not found');
      } else if (result.data.data.passwordResetRequired) {
        setUserId(result.data.data._id);
        await Auth.forgotPassword(email);
        setCodeSent(true);
        setResetPage(true);
      } else {
        setHideLogin(false);
      }
    } catch (error: any) {
      setFeedbackMessage(error.message);
      setNextLoading(false);
    }
  }

  return (
    <>
      {resetPage ? (
        !codeSent ? (
          <>
            <ResetForm
              setCodeSent={setCodeSent}
              setEnteredEmail={setEnteredEmail}
              setResetPage={setResetPage}
            />
          </>
        ) : !confirmed ? (
          <>
            <ConfirmationForm
              enteredEmail={enteredEmail}
              setConfirmed={setConfirmed}
              userId={userId}
            />
          </>
        ) : (
          <>
            <div className='h-screen flex flex-col gap-4 items-center justify-center'>
              <p>Password Successfully Updated!</p>
              <button
                onClick={() => setResetPage(false)}
                className={buttonStyle}
              >
                Back
              </button>
            </div>
          </>
        )
      ) : (
        <div className='flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
          <div className='w-full max-w-md space-y-8'>
            <div>
              <img
                className='mx-auto h-12 w-auto'
                src='https://res.cloudinary.com/belong/image/upload/v1650621217/uploaded_resources/logo_gqty7l.png'
                alt='Your Company'
              />
              <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
                Sign in to your account
              </h2>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email('Email must be a valid email address')
                  .required('Email is required'),
                password: Yup.string().required('Password is required'),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setFeedbackMessage('');
                Auth.signIn({
                  username: values.email,
                  password: values.password,
                })
                  .then((res: any) => {
                    const token = res?.signInUserSession?.accessToken?.jwtToken;
                    dispatch(setToken(token));
                    navigate(from, { replace: true });
                  })
                  .catch((err: any) => {
                    setFeedbackMessage(err?.message);
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form className='mt-8 space-y-6'>
                  <div className='-space-y-px rounded-md shadow-sm'>
                    <TextInput
                      label='Email address'
                      name='email'
                      type='text'
                      placeholder='Email'
                    />
                  </div>
                  {hideLogin ? (
                    <button
                      disabled={nextLoading}
                      onClick={() => checkEmail(values.email)}
                      type='button'
                      className='group relative flex w-full justify-center rounded-md border border-transparent bg-primary-light py-2 px-4 text-sm font-medium text-white hover:bg-primary focus:outline-none'
                    >
                      {nextLoading ? 'Loading...' : 'Next'}
                    </button>
                  ) : (
                    <>
                      <div>
                        <TextInput
                          label='Password'
                          name='password'
                          type='password'
                          placeholder='Password'
                        />
                      </div>

                      <div>
                        <button
                          type='submit'
                          className='group relative flex w-full justify-center rounded-md border border-transparent bg-primary-light py-2 px-4 text-sm font-medium text-white hover:bg-primary focus:outline-none'
                        >
                          <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                            <LockClosedIcon
                              className='h-5 w-5 text-primary group-hover:text-white'
                              aria-hidden='true'
                            />
                          </span>
                          Sign in
                        </button>
                      </div>
                    </>
                  )}
                  <div className='text-sm flex justify-end'>
                    <button
                      type='button'
                      // href='#'
                      className='font-medium text-primary hover:text-primary-light'
                    >
                      Forgot your password?
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            {feedbackMessage && <div> {feedbackMessage} </div>}
          </div>
        </div>
      )}
    </>
  );
};

export default LoginForm;
