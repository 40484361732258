import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTeamMembers: builder.query<any, any>({
      query: (slug) => {
        return {
          url: '/dashboard/partner/team-members',
          params: slug,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['TeamMembers'],
    }),

    addTeamMembers: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/admin/partner`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['TeamMembers'],
    }),
    editTeamMembers: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/admin/partner`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['TeamMembers'],
    }),
    deleteTeamMembers: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/admin/partner`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['TeamMembers'],
    }),
  }),
});

export const {
  useGetTeamMembersQuery,
  useAddTeamMembersMutation,
  useDeleteTeamMembersMutation,
  useEditTeamMembersMutation,
} = extendedApiSlice;
