import React from 'react';
import Applications from './Applications';
import Data from 'components/home/Data';

const index = () => {
  return (
    <div>
      <Data />
      <Applications />
    </div>
  );
};

export default index;
