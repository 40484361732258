import Stats from 'components/personalInformation/Stats';
import StudentDescription from 'components/personalInformation/StudentDescription';
import StudentEducation from 'components/personalInformation/StudentEducation';
import StudentExperience from 'components/personalInformation/StudentExperience';
import Loading from 'layout/Loading';
import React from 'react';
import { useGetPublicUserQuery } from './openSlice';

const StudentDetails = ({ userId, setUserId }: any) => {
  const { data, isLoading, isError, isSuccess } = useGetPublicUserQuery({
    userId,
  });
  return (
    <div>
      <div className='flex justify-end mr-6'>
        {' '}
        <button
          className='px-4 py-2 rounded bg-primary-light'
          onClick={() => setUserId(null)}
        >
          View All Applications
        </button>
      </div>
      <div>
        {isLoading && <Loading />}
        {isError && <div> Error.... </div>}
        {data && isSuccess && (
          <div className='text-neutral-900 sm:m-4'>
            <StudentDescription data={data} />
            {data?.education?.length > 0 && <StudentEducation data={data} />}
            {data?.workExperience?.length > 0 && (
              <StudentExperience data={data} />
            )}
            <Stats data={data} />
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentDetails;
