import React, { useEffect } from 'react';
import {
  ErrorMessage,
  Field,
  Formik,
  Form,
  FormikErrors,
  useFormikContext,
} from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorMessageStyle } from 'global/helper';
import { useGetPartnerQuery } from 'features/PartnerSlice';

const BasicDetails = ({
  setView,
  handleSwitch,
}: {
  setView: (value: number) => void;
  handleSwitch: any;
}) => {
  const { data, isSuccess } = useGetPartnerQuery();

  const initialValues = {
    name: data?.partnerId?.name || '',
    website: data?.partnerId?.website || '',
  };
  type FormValues = {
    name: string;
    website: string;
  };

  const ShowErrorsInToast = () => {
    // Grab values and submitForm from context
    const {
      errors,
      isSubmitting,
    }: {
      errors: FormikErrors<FormValues>;
      isSubmitting: boolean;
    } = useFormikContext();

    useEffect(() => {
      if (errors && isSubmitting) {
        //First dismiss all toasts
        toast.dismiss();
        //Show errors in toast
        Object.values(errors).forEach((value: string) => {
          toast.error(value);
        });
      }
    }, [errors, isSubmitting]);

    return null;
  };
  const inputStyle =
    'mt-1 block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm';
  return (
    <>
      <ToastContainer />
      {isSuccess && data && (
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            name: Yup.string().required('First Name is a required field.'),
            website: Yup.string()
              .url()
              .required('Website is a required field.'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSwitch({ type: 'UPDATE', values });
            setView(2);
            setSubmitting(false);
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <>
              <ShowErrorsInToast />
              <div className='px-4 py-5 sm:p-6'>
                <Form>
                  <div className='grid grid-cols-6 gap-4'>
                    <div className='col-span-6 sm:col-span-3'>
                      <label
                        htmlFor='name'
                        className='block text-sm font-medium text-neutral-900 md:font-bold'
                      >
                        Name
                      </label>
                      <Field
                        id='name'
                        type='text'
                        name='name'
                        placeholder='Acme Inc.'
                        className={inputStyle}
                      />
                      <div className={errorMessageStyle}>
                        <ErrorMessage name='name' />
                      </div>
                    </div>
                    <div className='col-span-6 sm:col-span-3'>
                      <label
                        htmlFor='website'
                        className='block text-sm font-medium text-neutral-900 md:font-bold'
                      >
                        Website
                      </label>
                      <Field
                        id='website'
                        type='text'
                        name='website'
                        placeholder='https://yourwebsite.com'
                        className={inputStyle}
                      />
                      <div className={errorMessageStyle}>
                        <ErrorMessage name='website' />
                      </div>
                    </div>
                  </div>
                  <div className='grid grid-cols-12 gap-4 mt-4'>
                    <div className='col-span-12 text-right'>
                      <button
                        disabled={isSubmitting}
                        className='bg-primary px-8 py-2 text-white font-light  text-sm rounded'
                      >
                        {isSubmitting ? 'Updating' : 'Next'}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default BasicDetails;
