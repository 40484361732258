import React from 'react';
import Achievements from './Achievements';
import Others from './Others';
import Projects from './Projects';
import Skills from './Skills';

const Stats = ({ data }: { data: any }) => {
  const others =
    data?.interests?.length ||
    data?.languages?.length ||
    data?.references?.length;
  return (
    <div className='grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-2 border-t-2 '>
      {data.projectPortfolio?.length > 0 && <Projects data={data} />}
      {data.certificates?.length > 0 && <Achievements data={data} />}
      {data.skills?.length > 0 && <Skills data={data} />}
      {others && <Others data={data} />}
    </div>
  );
};

export default Stats;
